import React, { createContext, useEffect, useState } from "react";
import Today from "./Today";
import Tomorrow from "./Tomorrow";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Yearly from "./Yearly";
import HoroscopeHeading from "./HoroscopeHeading";
import { Helmet } from "react-helmet-async";
// import { Adsense } from "@ctrl/react-adsense";
// import GoogleAdSense from "../../GoogleAdSense/GoogleAdSense";

export const HoroscopeContext = createContext("");
const Horoscope = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [horoscopePeriod, setHoroscopePeriod] = useState("Today's Horoscope");
  const handleHoroscopePeriod = (horoscopePeriod) => {
    setHoroscopePeriod(horoscopePeriod);
  };
  const horoscopeContext = { horoscopePeriod, handleHoroscopePeriod };

  return (
    <HoroscopeContext.Provider value={horoscopeContext}>
      <Helmet>
        <title>
          Unlock Your Destiny: Daily Horoscopes, Zodiac Signs, and Astrology
          Predictions
        </title>
        <meta
          name="description"
          content="Discover what the stars have in store for you! Explore daily horoscopes, personalized zodiac insights, and astrology predictions on our website. Unveil the mysteries of your future and harness the power of celestial guidance today."
        />
      </Helmet>
      <div
        style={{
          padding: "5.3%",
          marginTop: "14px",
          backgroundColor: "#FFE8D8",
        }}
      >
        <div>
          {/* <Adsense
        client="ca-pub-3052504703995882"
        slot="5066012899"
        style={{ display: "block" }}
        layout="in-article"
        format="fluid"
      /> */}
          {/* <GoogleAdSense /> */}

          <HoroscopeHeading />
          {horoscopePeriod === "Today's Horoscope" && <Today />}
          {horoscopePeriod === "Tomorrow's Horoscope" && <Tomorrow />}
          {horoscopePeriod === "Weekly Horoscope" && <Weekly />}
          {horoscopePeriod === "Monthly Horoscope" && <Monthly />}
          {horoscopePeriod === "Yearly Horoscope" && <Yearly />}
        </div>
      </div>
    </HoroscopeContext.Provider>
  );
};

export default Horoscope;
