import React from "react";
import { useTranslation } from "react-i18next";
import GoogleAdSense from "../../GoogleAdSense/GoogleAdSense";

const EventsHeading = () => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ marginBottom: "45px", marginTop: "39px" }}>
        <h2 className="route-heading">{t("Events_For_You")}</h2>
      </div>
      <div style={{ margin: "20px" }}>
        <GoogleAdSense />
      </div>
    </>
  );
};

export default EventsHeading;
