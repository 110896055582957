import React, { useEffect } from 'react';
import { Card, Row, Accordion, Container, Col, Button } from 'react-bootstrap';

const FaqPage = () => {

    const data = [
        {
            question: "What features does Bharat Calendars offer?",
            answer: "Bharat Calendars offers comprehensive calendar data, including traditional festivals, temple events, and personalized Kundli reports."
        },
        {
            question: "Where can I download the Bharat Calendars app?",
            answer: "You can download the Bharat Calendars app from the Google Play Store for Android devices and the Apple Store for iOS devices."
        },
        {
            question: "Is the Bharat Calendars app available in multiple languages?",
            answer: "Yes, the Bharat Calendars app is available in various regional languages to cater to the linguistic diversity of India."
        },
        {
            question: "Can I receive updates on festivals and events?",
            answer: "Yes, Bharat Calendars provides regular updates on festivals, temple events, and other cultural celebrations."
        },
        {
            question: "How accurate are the Kundli reports provided by Bharat Calendars?",
            answer: "Bharat Calendars ensures the accuracy of Kundli reports by employing reliable astrological techniques and algorithms."
        },
        {
            question: "Is Bharat Calendars available on social media?",
            answer: "Yes, you can follow Bharat Calendars on Instagram and Facebook for the latest updates, cultural insights, and community events."
        },
        {
            question: "Is Bharat Calendars suitable for personal use or can it be used for professional purposes as well?",
            answer: "Bharat Calendars caters to both personal and professional needs, providing users with essential calendar data and astrological insights."
        },
        {
            question: "How can I get in touch with Bharat Calendars for further inquiries?",
            answer: "For any further inquiries or assistance, you can reach out to Bharat Calendars through the contact section on the app or website."
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'start', textAlign: 'justify', fontSize: '1.2rem', padding: '0.5rem' }}>
            <Container>
                <h3
                    style={{ color: "#f16334", fontFamily: "fantasy", textAlign: "center", fontSize: "2rem", margin: '1rem 0rem 1.5rem 0rem' }}
                >
                    FAQ - Frequently Asked Questions
                </h3>
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Accordion>
                            {data.map((item, index) => (
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>
                                        {item.question}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {item.answer}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FaqPage;
