import React, { useContext, useState } from "react";
import { allHolidaysContext } from "./HolidaysContext";
import { Nav } from "react-bootstrap";
import HolidaysData from "./HolidaysData";
import "./HolidayPageStyles.scss";
import { Helmet } from "react-helmet-async";

function HolidaysPage() {
  const { holidays, loading, error } = useContext(allHolidaysContext);
  const [activeTab, setActiveTab] = useState("Hindu");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  if (loading) {
    return (
      <div className="text-center mt-4 fs-3 text-danger">
        ...Loading Holidays...
      </div>
    );
  }

  if (error) {
    return <div>Error fetching holidays: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Celebrate Festivals and Special Occasions with Bharat Calendars
        </title>
        <meta
          name="description"
          content="Discover a world of joy and celebration with Bharat Calendars Holidays. Explore a diverse collection of festivals, events, and special occasions. Dive into the cultural richness of Bharat, mark your calendar with festivities, and create lasting memories. Join us in celebrating life's vibrant tapestry!"
        />
      </Helmet>
      <div className="text-center">
        <p className="holiday-header fs-2 mt-3">Holidays</p>

        <Nav fill variant="pills" defaultActiveKey={activeTab}>
          <Nav.Item>
            <Nav.Link
              eventKey="Hindu"
              onClick={() => handleTabClick("Hindu")}
              className="me-1"
              style={{
                backgroundColor: activeTab === "Hindu" ? "orange" : "",
              }}
            >
              Hindu
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Christian"
              onClick={() => handleTabClick("Christian")}
              className="me-1"
              style={{
                backgroundColor: activeTab === "Christian" ? "orange" : "",
              }}
            >
              Christian
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Government"
              onClick={() => handleTabClick("Government")}
              className="me-1"
              style={{
                backgroundColor: activeTab === "Government" ? "orange" : "",
              }}
            >
              Government
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Islamic"
              onClick={() => handleTabClick("Islamic")}
              className="me-1"
              style={{
                backgroundColor: activeTab === "Islamic" ? "orange" : "",
              }}
            >
              Islamic
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <hr style={{ color: "tomato" }} />
      <div className="mt-3">
        <HolidaysData holidays={holidays[activeTab]} />
      </div>
    </>
  );
}

export default HolidaysPage;
