import React, { useState } from "react";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import "./signIn.scss";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function Signin() {
  const navigate = useNavigate();
  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 6) {
      errors.password = "Must be 6 characters or more";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {},
  });

  return (
    <>
      <Helmet>
        <title>Sign In to Your Bharat Calendars Account</title>
        <meta
          name="description"
          content="Access your personalized experience with Bharat Calendars by signing in to your account. Stay updated with horoscopes, festivals, and events tailored just for you. Sign in now and embark on a journey of self-discovery and cosmic guidance."
        />
      </Helmet>
      <div style={{ paddingTop: "3.3%" }}>
        <div className="container page-main-contain">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-12 signup-title text-center">
              <h1>Let's Get Started</h1>
              <p>Enter Your Email To Sign Up or Log in.</p>
            </div>
          </div>
          <div className="row sign-in-form">
            <div className="col-md-12 col-sm-12 col-lg-12">
              <Form onSubmit={formik.handleSubmit} className="contact-form">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12 form-group">
                    <Form.Group size="lg" controlId="email">
                      <Form.Control
                        className="email"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        placeholder="Enter Email"
                      />
                      {formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 form-group">
                    <Form.Group size="lg" controlId="password">
                      <Form.Control
                        className="email"
                        type="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        placeholder="Enter Password"
                      />
                      {formik.errors.password ? (
                        <div className="error">{formik.errors.password}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 ">
                    <button
                      className="get_started"
                      type="submit"
                      disabled={!validate}
                    >
                      <b>GET STARTED</b>
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-5 col-lg-5">
                    <br />
                    <span
                      className="forget_password"
                      onClick={() => {
                        navigate("/reset");
                      }}
                    >
                      Forget Password ?
                    </span>
                  </div>
                  <div className="col-md-7 col-sm-12 col-lg-7">
                    <br />
                    <span
                      className="new-user"
                      onClick={() => {
                        navigate("/signup");
                      }}
                    >
                      Registration for new user
                    </span>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signin;
