import React from "react";
import moment from "moment";
import useHoroscopeFetch from "../../Hooks/useHoroscopeFetch";
import HoroscopeDetails from "./HoroscopeDetails";
import HoroscopeButtons from "./HoroscopeButtons";
import { useTranslation } from "react-i18next";

const Today = () => {
  if (!localStorage.getItem("rashi")) {
    localStorage.setItem("rashi", "ARIES");
  }

  const date = moment(new Date()).format("DD-MM-YYYY");
  const { t } = useTranslation();
  const { data, refetch } = useHoroscopeFetch(date);

  const handleRashi = (rashi) => {
    localStorage.setItem("rashi", rashi);
    refetch();
  };
  return (
    <div>
      <HoroscopeButtons handleRashi={handleRashi} />
      <h5 className="montserrat-font mt-3 mt-lg-5" style={{ fontSize: "15px" }}>
        {t("Today's Horoscope")} : {date}
      </h5>
      {data && <HoroscopeDetails data={data} />}
    </div>
  );
};

export default Today;
