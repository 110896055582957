import React from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

const MobileFrame = () => {
  const { t } = useTranslation();
  const androidUrl = localStorage.getItem("REACT_APP_ANDROID_URL");
  const iosUrl = localStorage.getItem("REACT_APP_IOS_URL");

  const trackAppDownload = (platform) => {
    ReactGA.event({
      category: "App Download",
      action: "Click",
      label: platform,
    });
  };

  return (
    <div id="footer-top" className="row">
      <div className="col-12 col-lg-6 mb-5">
        <div id="mobile-frame-box">
          <img
            id="img-frame-2"
            className="img-fluid mobile-frame"
            src="/img/mobile_frame/mobile_frame_2.png"
            alt=""
          />
          <img
            id="img-frame-1"
            className="img-fluid mobile-frame"
            src="/img/mobile_frame/mobile_frame_1.png"
            alt=""
          />
        </div>
      </div>
      <div className="col-12 col-lg-6 mt-5">
        <div className="mb-3">
          <h3 className="text-uppercase footer-title">
            <span className="footer-title-span">{t("ABOUT_US")}</span>
          </h3>
          <div className="row">
            <div className="col-lg col-sm col-md">
              <p
                className="text-justify montserrat-font fs-6"
                style={{ color: "#777777" }}
              >
                Eventz is here to promote Jazz, Blues, Soul, World Music and
                Reggae concerts and artists. Our mission is to connect jazz
                lovers to the artists they love through live shows. Artists and
                concert promoters can leverage our platform to generate more
                revenue for their hard work as artists.
              </p>
            </div>
          </div>
        </div>
        <div className="link-section" style={{ marginBottom: "65px" }}>
          <h5 style={{ color: "#777777", fontWeight: 600 }}>
            Get Bharat Calendars App
          </h5>
          <div className="d-flex text-center mt-2">
            <div className="me-5">
              <div className="text-start">
                <a
                  href={androidUrl}
                  target="_blank"
                  rel="noreferrer"
                  title="Eventz Organizer Android App"
                  onClick={() => trackAppDownload("Android")}
                >
                  <img alt="" src="/img/play-store.png" className="img-fluid" />
                </a>
              </div>
            </div>
            <div>
              <div className="text-start">
                <a
                  href={iosUrl}
                  target="_blank"
                  rel="noreferrer"
                  title="Eventz Organizer iOS App"
                  onClick={() => trackAppDownload("ios")}
                >
                  <img alt="" src="/img/app-store.png" className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFrame;
