import React, { useContext } from "react";
//import { useState } from "react";
//import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import sliderImg1 from "../../img/home/slider.png";
import sliderImg2 from "../../img/banner-1-shop.jpg";
import sliderImg3 from "../../img/banner-2-shop.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "./HomeSlider.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { allShopItemsContext } from "../../Components/BharatWebShopContext/ShopTodaysDeals";

const HomeSlider = () => {
  // const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const { shopData } = useContext(allShopItemsContext);

  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };

  // const handleBannerClick = (item) => {
  //   let itemId;
  //   if (item === "Rudraksha") {
  //     itemId = "652ead5b9a6c61b1a8538851";
  //   } else if (item === "LakshmiStatue") {
  //     itemId = "652ead5b9a6c61b1a853882a";
  //   }

  //   // Navigate to the product details page
  //   navigate(`/item/${itemId}`);
  // };

  const getItemIdByName = (itemName) => {
    const selectedItem = shopData.find((item) => item.itemName === itemName);
    return selectedItem ? selectedItem._id : null;
  };

  const handleBannerClick = (itemName) => {
    const itemId = getItemIdByName(itemName);

    if (itemId) {
      //navigate(`/item/${itemId}`);
      window.location.href = `https://shop.bharatcalendars.in/categoryItems/${itemId}`;
    } else {
      console.error(`Item with name ${itemName} not found.`);
    }
  };

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination]}
      className="mySwiper mt-1 mb-3 mb-lg-5 rounded"
    >
      <SwiperSlide>
        <img
          src={sliderImg1}
          className="img-fluid h-50 w-100 rounded swiper-img"
          alt="img"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={sliderImg2}
          className="img-fluid h-50 w-100 swiper-img rounded"
          alt="img"
          onClick={() => handleBannerClick("Rudraksha Mala 6mm (108 Beads)")}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={sliderImg3}
          className="img-fluid h-50 w-100 swiper-img rounded"
          alt="img"
          onClick={() =>
            handleBannerClick("Lakshmi Standing Brass Idol [ Height - 4 inch]")
          }
        />
      </SwiperSlide>
    </Swiper>

    // <div className="h-5">
    //   <img src={sliderImg} className="img-fluid pt-5 pb-3 pb-lg-5" alt="" />
    // </div>

    // <div className="img-fluid pt-5 pb-3 pb-lg-5">
    //   <Carousel activeIndex={index} onSelect={handleSelect}>
    //     <Carousel.Item>
    //       <img
    //         src={sliderImg1}
    //         className="img-fluid"
    //         alt=""
    //       />
    //     </Carousel.Item>
    //     <Carousel.Item>
    //       <img
    //         src={sliderImg1}
    //         // className="img-fluid pt-5 pb-3 pb-lg-5"
    //         className="img-fluid"
    //         alt=""
    //       />
    //     </Carousel.Item>
    //     <Carousel.Item>
    //       <img
    //         src={sliderImg1}
    //         // className="img-fluid pt-5 pb-3 pb-lg-5"
    //         className="img-fluid"
    //         alt=""
    //       />
    //     </Carousel.Item>
    //   </Carousel>
    // </div>
  );
};

export default HomeSlider;
