import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'start',textAlign:'justify',fontSize:'1.2rem',padding:'0.5rem' }}>
      <Container>
      <h3
        style={{ color: "#f16334", fontFamily: "fantasy", textAlign: "center", fontSize: "2rem",margin:'1rem 0rem'}}
      >
        About Us
      </h3>
        <Row className="justify-content-center">
          <Col md={8}>
            <p>Welcome to Bharat Calendars, your ultimate destination for authentic Indian calendars and Panchang. At Bharat Calendars, we pride ourselves on providing comprehensive and accurate calendar data tailored to the diverse cultural landscape of India. From the snow-capped peaks of the Himalayas to the sun-kissed shores of the Indian Ocean, we cater to the unique needs of each Indian state, offering customized apps in various regional languages and panchang.</p>
            <p>Our calendars not only highlight traditional festivals and temple events but also provide detailed Kundli reports, personalized to each user's astrological profile. Whether you're planning a religious pilgrimage, scheduling an auspicious event, or simply staying connected to your roots, Bharat Calendars is your trusted companion on the journey through the rich tapestry of Indian culture and spirituality.</p>
            <p>Explore our wide range of features and services, and join millions of satisfied users who rely on Bharat Calendars for their daily, monthly, and yearly planning needs.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;
