import React, { useContext, useEffect, useState } from "react";
import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
//import { Link } from "react-router-dom";

function AllNewlyAddedItems() {
  const { loading, error, fetchNewlyAddedItemsData } =
    useContext(allShopItemsContext);
  const [shopData, setShopData] = useState([]);

  useEffect(() => {
    const fetchTodayDeals = async () => {
      try {
        const NewlyAddedItems = await fetchNewlyAddedItemsData();
        setShopData(NewlyAddedItems);
      } catch (error) {
        console.error("Error fetching all deals on newly added items:", error);
      }
    };

    fetchTodayDeals();
  }, [fetchNewlyAddedItemsData]);

  if (loading) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return (
      <div>
        {/* <img
          src={Loader}
          alt="Loading Today's Deals..."
          style={{ width: "100%", height: "400px", borderRadius: "100%" }}
        /> */}
        <h3 className="text-center text-warning">
          ...Loading Newly Added Items...
        </h3>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const getImageUrl = (imagePath) => {
    const baseUrl = "https://api.exaweb.in:5200";
    const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");
    return `${baseUrl}/${normalizedPath}`;
  };

  const handleItemClick = (itemId) => {
    window.location.href = `https://shop.bharatcalendars.in/categoryItems/${itemId}`;
  };

  return (
    <div className="ml-4 mb-5">
      <div>
        <p
          style={{
            fontSize: "35px",
            fontFamily: "montserrat-font",
            fontWeight: "900",
            textAlign: "center",
            color: "black",
            marginTop: "19px",
          }}
        >
          Newly Added To Store
        </p>
      </div>
      <div
        className="mt-3 p-2"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        {shopData.map((item, index) => (
          <div
            className="card shadow p-3 bg-white rounded"
            key={item._id}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "200px",
              height: "300px",
              margin: "15px",
              padding: "0px",
              cursor: "pointer",
              boxSizing: "border-box",
            }}
            onClick={() => handleItemClick(item._id)}
          >
            <div className="card-img-top">
              <img
                src={getImageUrl(item.imageGallery[0])}
                alt={`Item ${index + 1}`}
                style={{
                  maxWidth: "100%",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "7px",
                  margin: "5px auto",
                  display: "block",
                }}
              />
            </div>
            <div
              className="text-dark"
              style={{ padding: '0.5rem 0rem' }}
            >
              <p >
                {item.itemName.slice(0, 200)}
              </p>
            </div>
            <div className="card-footer fw-bolder text-center">
              <p style={{ margin: "0" }}>
                ₹{item.price}{" "}
                <span className="text-secondary text-decoration-line-through">
                  ₹{item.discPrice}
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllNewlyAddedItems;
