import React, { useContext } from "react";
import "./FestivalPageStyles.css";
import { allFestivalsContext } from "./FestivalContext";
import { Helmet } from "react-helmet-async";
//import { useTranslation } from "react-i18next";

function FestivalPage() {
  const { festivals, loading, error } = useContext(allFestivalsContext);
  //const { t } = useTranslation();

  if (loading) {
    return (
      <div className="text-center mt-4 fs-3 text-danger">
        ...Loading festivals...
      </div>
    );
  }

  if (error) {
    return <div>Error fetching festivals: {error.message}</div>;
  }

  return (
    <div className="mb-5">
      <Helmet>
        <title>Celebrate Bharat's Rich Festivals - Bharat Calendars</title>
        <meta
          name="description"
          content="Embark on a journey of joy and cultural significance as you explore the festivals of Bharat with Bharat Calendars. Our Festivals page is a vibrant showcase of traditional celebrations, rituals, and festivities across the country. Dive into the spirit of unity, diversity, and cultural heritage. Join the celebration with Bharat Calendars!"
        />
      </Helmet>
      <h1 className="text-center festival-header m-4">Festivals</h1>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {festivals.map((item, index) => (
            <div key={index} className="col-6">
              <div className="card h-100" style={{ minWidth: "10rem" }}>
                <div className="card-body">
                  <p className="card-text festival-date">{item.date}</p>
                  <ul>
                    {item.festivals.map((festival, festivalIndex) => (
                      <li key={festivalIndex} className="festival-name">
                        {festival}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FestivalPage;
