import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./ArticleListing.scss";
import Loading from "../Loading/Loading";

const ArticleListing = ({ articleId }) => {
  const { i18n } = useTranslation();
  const language = i18n.language.toUpperCase();
  const navigate = useNavigate();
  // const REACT_APP_DEV_ENDPOINT = process.env.REACT_APP_DEV_ENDPOINT;
  const REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const url = `${REACT_APP_ENDPOINT}article?language=${language}&status=1&catid=${articleId}&mobile=true`;

  const { data: articleList, isLoading } = useQuery(
    [`articleList`, articleId],
    () => axios.get(url).then((data) => data.data.data)
  );

  // State to manage the number of articles to display
  const [visibleArticles, setVisibleArticles] = useState(15);

  if (isLoading) {
    return <Loading />;
  }

  const loadMoreArticles = () => {
    // Increment the number of visible articles by 15
    setVisibleArticles((prevVisibleArticles) => prevVisibleArticles + 15);
  };

  return (
    <div className="my-5">
      <h3
        style={{ color: "#f16334", fontFamily: "fantasy", textAlign: "center" }}
      >
        Articles
      </h3>

      <div className="row g-5 mb-3">
        {articleList?.length > 0 ? (
          articleList.slice(0, visibleArticles).map((article, index) => (
            <button
              onClick={() => navigate(`/article/${article._id}`)}
              key={index}
              className="col-lg-4 article-card-button"
            >
              <div className="article-card shadow">
                <img
                  alt="article"
                  src={article.image}
                  className="article-card-img img-fluid"
                />
                <div
                  className="article-title-div"
                  style={{ backgroundColor: "#f16334" }}
                >
                  <p className="article-title fw-semibold text-white">
                    {article.name}
                  </p>
                </div>
              </div>
            </button>
          ))
        ) : (
          <div>
            <h2 style={{ color: "#f16334" }} className="fw-bold">
              No articles Found
            </h2>
          </div>
        )}
      </div>

      {/* Load More Button */}
      {articleList?.length > visibleArticles && (
        <div className="text-center mt-5">
          <button
            className="btn btn-primary"
            onClick={loadMoreArticles}
            style={{ backgroundColor: "#f16334", border: "none" }}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default ArticleListing;
