import axios from "axios";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useHoroscopeFetch from "../../Hooks/useHoroscopeFetch";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../Components/Loading/Loading";

const HomeHoroscope = () => {
  const { t } = useTranslation();
  // const [signs, setSigns] = useState([]);
  // useEffect(() => {
  //   axios.get("jsons/signs.json").then((data) => setSigns(data.data));
  // }, []);

  const { data: signs = [], isLoading } = useQuery({
    queryKey: ["signs"],
    queryFn: async () => {
      const res = await axios.get("jsons/signs.json");
      return res.data;
    },
  });

  if (!localStorage.getItem("rashi")) {
    localStorage.setItem("rashi", "ARIES");
  }
  const rashi = localStorage.getItem("rashi");
  const date = moment(new Date()).format("DD-MM-YYYY");

  const { data, refetch, detailsLoading } = useHoroscopeFetch(date);
  const horoscopeDetails = data?.[rashi];

  //   let keys;
  //   if (horoscopeDetails) {
  //     keys = Object.keys(horoscopeDetails);
  //   }
  const handleRashi = (rashi) => {
    localStorage.setItem("rashi", rashi);
    refetch();
  };
  const navigate = useNavigate();
  return (
    <>
      <div style={{ marginBottom: "3vw" }}>
        <h2 className="route-heading mt-3">{t("Today's Horoscope")}</h2>
        {/* <p className="montserrat-font" style={{ textAlign: "justify" }}>
          {t("Horoscope Heading")}
        </p> */}
      </div>

      {detailsLoading ? (
        <Loading></Loading>
      ) : (
        <>
          <div className="d-flex gap-5 flex-column flex-lg-row justify-content-center align-items-center">
            <div className="w-100">
              <div className="mb-2">
                <h6 className="poppins-font" style={{ color: "#EB7625" }}>
                  {t(`${rashi}`)}
                </h6>
                <h6 className="montserrat-font" style={{ color: "#828282" }}>
                  {t("Choose an option")}
                </h6>
              </div>
              <div className="row">
                {signs.map((sign, index) => (
                  <button
                    key={index}
                    className={`col-3 col-lg-3 col-md-4 bg-transparent border-0 d-flex flex-column align-items-center ${
                      rashi === sign?.name.toUpperCase()
                        ? "home-horoscope-sign-clicked"
                        : "home-horoscope-sign-not-clicked"
                    }`}
                    onClick={() => handleRashi(sign?.name.toUpperCase())}
                  >
                    <img src={sign?.image} alt="" />
                    <p
                      className="montserrat-font"
                      style={{
                        fontSize: "1.32vw",
                      }}
                    >
                      {sign?.name}
                    </p>
                  </button>
                ))}
              </div>
            </div>

            <div className="home-horoscope-details w-100">
              <div className="accordion-body">
                <div className="d-flex justify-content-between py-2 py-md-5 py-lg-5  ">
                  <div className="d-flex flex-column align-items-center">
                    <p className="text-white montserrat-font mb-2">{date}</p>
                    <div
                      style={{
                        height: "0.37vw",
                        width: "10vw",
                        background: "#EB7625",
                      }}
                    />
                  </div>
                  <button
                    className="text-white home-horoscope-details-button"
                    onClick={() => navigate("/horoscope")}
                  >
                    View All
                  </button>
                </div>

                <>
                  <Accordion
                    defaultActiveKey="prediction"
                    // style={{ fontSize: "1vw" }}
                  >
                    <Accordion.Item
                      eventKey="prediction"
                      className="horoscope-details-accordion"
                    >
                      <Accordion.Header>{t("Prediction")}</Accordion.Header>
                      <Accordion.Body className="home-prediction-body">
                        {horoscopeDetails?.["Prediction"]}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey="lucky-numbers"
                      className="horoscope-details-accordion"
                    >
                      <Accordion.Header>{t("Lucky Numbers")}</Accordion.Header>
                      <Accordion.Body>
                        {horoscopeDetails?.["Lucky Numbers"]}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey="lucky-colour"
                      className="horoscope-details-accordion"
                    >
                      <Accordion.Header>{t("Lucky Colour")}</Accordion.Header>
                      <Accordion.Body>
                        {horoscopeDetails?.["Lucky Colour"]}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HomeHoroscope;
