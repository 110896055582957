import {
  nearByEventApi,
  popularEventApi,
  showNearBy,
  showPopular,
} from "../../store/actions/eventActions";
import Card from "../../Components/Card/card";
import { useDispatch, useSelector } from "react-redux";
import "./home.scss";
import { useEffect, useState } from "react";
import Slider from "../../Components/Slider/slider";
import { useTranslation } from "react-i18next";
import { gapi } from "gapi-script";
import SuggestedAccount from "../../Components/suggested_account/suggested_account";
import { getFutureEvents } from "../../store/actions/eventsActions";
import { getSuggestedAccount } from "../../store/actions/userActions";
import { Link } from "react-router-dom";
import HomeSlider from "./HomeSlider";
import HomeButtons from "./HomeButtons";
import HomeHoroscope from "./HomeHoroscope";
import HomePanchang from "./HomePanchang";
import PopularEvents from "./PopularEvents";
import UpcomingEvents from "./UpcomingEvents";
import NearbyEvents from "./NearbyEvents";
import { CNav } from "@coreui/react";
import ShopApiContext from "../../Components/BharatWebShopContext/ShopTodaysDeals";
import BharatShopIndex from "../../Components/BharatWebShop/BharatWebShopIndex";
import NewlyAddedItemsIndex from "../../Components/BharatWebShop/NewlyAddedItems";
import ShopPopularItemsIndex from "../../Components/BharatWebShop/ShopPopularItems";
import { Helmet } from "react-helmet-async";

function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);
  const [latlong, setLatLong] = useState();
  const popularEvents = useSelector(showPopular);
  console.log("popularEvents", popularEvents);
  const nearByEvents = useSelector(showNearBy);
  const user = useSelector((state) => state?.user?.userdetail);
  const upcomingEvents = useSelector((state) => state?.userEvents?.future);
  const token = localStorage.getItem("token");
  localStorage.setItem("rashi", "");
  const suggestedUsers = useSelector((state) => state?.SearchUser?.suggested);
  const tempLocation = JSON.parse(sessionStorage.getItem("tempLocation"));

  useEffect(() => {
    if (latlong !== undefined || tempLocation?.lat || tempLocation?.lng) {
      const data = {
        language: user?.language
          ? user?.language
          : localStorage.getItem("REACT_APP_LANGUAGE"),
        userId: user?._id ? user?._id : "",
        nearBy: true,
        lat: tempLocation ? tempLocation?.lat : latlong?.lat.toFixed(2),
        long: tempLocation ? tempLocation?.lng : latlong?.lng.toFixed(2),
      };
      dispatch(popularEventApi(data));
    } else {
      const data = {
        language: user?.language
          ? user?.language
          : localStorage.getItem("REACT_APP_LANGUAGE"),
        userId: user?._id ? user?._id : "",
      };
      dispatch(popularEventApi(data));
    }
  }, [tempLocation?.lat, tempLocation?.lng, latlong]);

  useEffect(() => {
    if (token) {
      const data = {
        long: user?.location?.coordinates[0].toFixed(2),
        lat: user?.location?.coordinates[1].toFixed(2),
        userId: user?._id,
      };
      dispatch(getSuggestedAccount(data));
    }
  }, [token, user?._id, user?.location?.coordinates, dispatch]);

  useEffect(() => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          if (position?.coords?.latitude && position?.coords?.longitude) {
            setLatLong({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }
        },
        () => {
          setStatus("Unable to retrieve your location");
        }
      );
    }
  }, []);

  useEffect(() => {
    if (token !== null) {
      const futuredata = {
        userId: user?._id,
        filter: "upcoming",
      };
      dispatch(getFutureEvents(futuredata));
    }
  }, [token, user?._id, dispatch]);

  useEffect(() => {
    if (latlong !== undefined || tempLocation?.lat || tempLocation?.lng) {
      const data = {
        eventStatus: "APPROVED",
        distance: 300000,
        language: user?.language
          ? user?.language
          : localStorage.getItem("REACT_APP_LANGUAGE"),
        nearBy: true,
        lat: tempLocation ? tempLocation?.lat : latlong?.lat.toFixed(2),
        long: tempLocation ? tempLocation?.lng : latlong?.lng.toFixed(2),
      };
      dispatch(nearByEventApi(data));
    }
  }, [tempLocation?.lat, tempLocation?.lng, latlong]);

  return (
    <div
      style={{
        padding: "40px",
        marginTop: "13px",
        backgroundColor: "#FFE8D8",
      }}
    >
      <Helmet>
        <title>Bharat Calenders</title>
        <meta
          name="description"
          content="Discover the cultural richness and vibrant traditions of Bharat with Bharat Calendars - your go-to application for staying connected with festivals, events, and important dates across the country."
        />
      </Helmet>
      <ShopApiContext>
        <HomeSlider />
      </ShopApiContext>

      <HomeButtons />
      <HomeHoroscope />
      <HomePanchang />
      <ShopApiContext>
        <BharatShopIndex />
        <NewlyAddedItemsIndex />
        <ShopPopularItemsIndex />
      </ShopApiContext>

      <>
        {/* <Slider t={t} /> */}
        {/* <div className="section-bg"> */}
        <PopularEvents popularEvents={popularEvents} />
        {token && <UpcomingEvents upcomingEvents={upcomingEvents} />}
        {token && <NearbyEvents nearByEvents={nearByEvents} />}
        {/* {event?.length > 0 && (
            <div className="row m-0 p-0">
              <div className="col-lg-12 text-center content-title">
                <h1 style={{ marginBottom: "20px" }}>{t("Popular Events")}</h1>
              </div>
            </div>
          )} 
          <div className="container card-design">
            {popularEvent?.length > 0 &&
              popularEvent?.map((value, index) => {
                return (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                  >
                    <Card event={value} index={index} />
                  </div>
                );
              })}
          </div> */}

        {/* {token && futureEvents?.length > 0 && (
            <div className="row m-0 p-0">
              <div className="col-lg-12">
                <h1 style={{ marginBottom: "20px" }}>{t("Upcoming Events")}</h1>
              </div>
            </div>
          )}
          {token && (
            <div className="container card-design">
              {futureEvents?.length > 0 &&
                futureEvents?.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                    >
                      <Card event={value} index={index} />
                    </div>
                  );
                })}
            </div>
          )} */}
        {/* {nearByEvent?.length > 0 && (
          <div className="row m-0 p-0">
            <div className="col-lg-12 text-center content-title">
              <h1 style={{ marginBottom: "20px" }}>{t("Nearby Events")}</h1>
            </div>
          </div>
        )}
        <div className="container card-design">
          {nearByEvent?.length > 0 &&
            nearByEvent?.map((value, index) => {
              return (
                <div
                  key={index}
                  className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                >
                  <Card event={value} index={index} />
                </div>
              );
            })}
        </div> */}
        {/* {nearByEvent?.length > 9 && (
          <div className="row m-0 p-0">
            <div className="col-lg text-center" style={{ marginTop: "40px" }}>
              <br />
              <Link to="#" className="see-more">
                {t("SEE MORE")}
              </Link>
            </div>
          </div>
        )} */}
        {/* </div> */}
        {/* {token &&
          suggestedUsers?.length !== 0 &&
          suggestedUsers?.length !== undefined && (
            <div className="row container">
              <div className="col-lg-12 suggested">
                <span>{t("Suggested Account")}</span>
              </div>
              <SuggestedAccount suggestedUsers={suggestedUsers} />
            </div>
          )} */}
      </>
    </div>
  );
}

export default Home;
