import React, { useContext, useEffect, useState } from "react";
import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import "./PopularItems.scss";

function ShopPopularItemsIndex() {
  const { loading, error, fetchPopularItemsData } =
    useContext(allShopItemsContext);
  const [shopData, setShopData] = useState([]);

  useEffect(() => {
    const fetchTodayDeals = async () => {
      try {
        const PopularItems = await fetchPopularItemsData();
        setShopData(PopularItems.slice(0, 8));
      } catch (error) {
        console.error("Error fetching popular items:", error);
      }
    };

    fetchTodayDeals();
  }, [fetchPopularItemsData]);

  if (loading) {
    return (
      <div>
        <h3 className="text-center text-warning m-3">
          ...Loading Popular Items...
        </h3>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const getImageUrl = (imagePath) => {
    const baseUrl = "https://api.exaweb.in:5200";
    const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");
    return `${baseUrl}/${normalizedPath}`;
  };

  const handleItemClick = (itemId) => {
    window.location.href = `https://shop.bharatcalendars.in/categoryItems/${itemId}`;
  };

  return (
    <>
      <div className="mt-4 text-dark">
        <div>
          <p
            style={{
              fontSize: "35px",
              fontFamily: "montserrat-font",
              fontWeight: "900",
              textAlign: "center",
            }}
            className="title"
          >
            Popular Items
          </p>
        </div>
        <div className="row row-cols-1 row-cols-md-2 g-5 mb-3 justify-content-md-between justify-content-center"
          style={{
            borderRadius: "18px",
            padding: "9px",
            backgroundColor: "wheat",
            display: "flex",
            justifyContent: "space-between",
            margin: "0",
          }}
        >
          {shopData.map((item, index) => {
            const itemName = item.itemName || "";
            const truncatedName = itemName.slice(0, 15);
            return (
              <div
                className="rounded bg-dark "
                key={item._id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "170px",
                  height: "280px",
                  margin: "5px",
                  cursor: "pointer",
                }}
                onClick={() => handleItemClick(item._id)}
              >
                <div className="card-img-top text-center">
                  <img
                    src={getImageUrl(item.imageGallery[0])}
                    alt={`Item ${index + 1}`}
                    style={{
                      maxWidth: "100%",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "7px",
                      marginTop:'1rem',
                      display: "block",
                    }}
                  />
                </div>
                <div
                  className=" text-light"
                  style={{ padding:'0.5rem 0rem' }}
                >
                  <p className="item-name fs-6">{truncatedName}</p>
                </div>
                <div className="card-footer" style={{backgroundColor:'green',height:'50px',marginBottom:'1rem',borderRadius:'1rem'}}>
                  <p className="d-flex justify-content-center align-items-center pt-2">
                    <span className="fs-5 text-light">₹{item.discPrice}</span>
                    <span className="fs-6 text-secondary text-decoration-line-through">
                      ₹{item.price}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <p className="text-end p-1">
        <Link
          to="/all-popular-items"
          className="text-decoration-none text-danger fs-6"
        >
          See All
          <IoIosArrowDroprightCircle />
        </Link>
      </p>
    </>
  );
}

export default ShopPopularItemsIndex;
