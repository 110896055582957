import React from "react";
import { useTranslation } from "react-i18next";
import GoogleAdSense from "../../GoogleAdSense/GoogleAdSense";

const PanchangHeading = () => {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: "3vw" }}>
      <h2 className="route-heading">{t("Panchang")}</h2>
      <p className="montserrat-font" style={{ textAlign: "justify" }}>
        {/* {t("Panchang Heading")} */}
      </p>

      <div style={{ margin: "9px 0" }}>
        <GoogleAdSense />
      </div>
    </div>
  );
};

export default PanchangHeading;
