import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
//import { initGA, logPageView } from "../../UserAnalytics/UserAnalytics";
// import "./header.scss";
import { RiSearchLine } from "react-icons/ri";
import { BiCurrentLocation } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.scss";
import {
  Container,
  Dropdown,
  DropdownButton,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Collapse,
  NavDropdown,
} from "react-bootstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import LoginModal from "../Modal/Login/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import Geocode from "react-geocode";
import Search from "../../search/search";
import { findEventApi, nearByEventApi } from "../../store/actions/eventActions";
import { usePlacesWidget } from "react-google-autocomplete";
import ProfileDropdown from "../ProfileDropdown";
import { authAction } from "../../store/slice/authSlice";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import Sidemenudrawer from "../SideMenuDrawer";
import { getSearchUserData } from "../../store/actions/userActions";
import { toast } from "react-toastify";
import NewEvent from "../Modal/newEvent/newEvent";
import CustomLink from "./CustomLink";
import { FaBagShopping } from "react-icons/fa6";
import { MdShoppingCart } from "react-icons/md";
import translations from "../Translations/Translations.json";

function Header({ showMenu, setShowMenu }) {
  const logo = localStorage.getItem("REACT_APP_LOGO");
  const limit = "25";
  const { t, i18n } = useTranslation();
  const [chanSearch, setchanSearch] = useState("Events");
  const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [tops, setTops] = useState(0);
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);

  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
  const [search, setSearch] = useState("");

  const [showsearch, setShowSearch] = useState(false);
  const handleCloseSearch = () => setShowSearch(false);
  // const handleShowSearch = () => setShowSearch(true);

  const [showCreate, setShowCreate] = useState(false);

  const [cartCount, setCartCount] = useState(0);

  const [expanded, setExpanded] = useState(false);

  const [inputFocused, setInputFocused] = useState(false);

  // const handleToggleMenu = () => {
  //   setShowMenu(!showMenu);
  // };

  const handleCloseCreate = () => setShowCreate(false);
  const handleShowCreate = () => setShowCreate(true);
  const userData = useSelector((state) => state?.user);
  const [fOptions, setFOptions] = useState([]);
  const token = localStorage.getItem("token");
  const l = localStorage.getItem("REACT_APP_LANGUAGE");
  const selectedLang = localStorage.getItem("lang");
  const options = [
    { value: "en", label: t("English") },
    { value: "gu", label: t("Gujarati") },
    { value: "hi", label: t("Hindi") },
    { value: "mr", label: t("Marathi") },
    { value: "kn", label: t("Kannada") },
    { value: "ta", label: t("Tamil") },
    { value: "bn", label: t("Bengali") },
    { value: "ml", label: t("Malayalam") },
    { value: "te", label: t("Telugu") },
    { value: "or", label: t("Odia") },
  ];
  const storedLanguage = localStorage.getItem("lang") || "en";
  const selectedTranslation = translations[storedLanguage] || translations.en;

  const MEASUREMENT_ID = "G-KWM6FFPZ1T";
  ReactGA.initialize(MEASUREMENT_ID);

  // useEffect(() => {
  //   const MEASUREMENT_ID = "G-15KCCTC57J";
  //   initGA(MEASUREMENT_ID);
  //   logPageView();
  // }, []);

  useEffect(() => {
    if (l.toLowerCase() === "en" || l.toLowerCase() === "ms") {
      setFOptions(options);
    } else {
      setFOptions(
        options.filter((options) => {
          return options.value === l.toLowerCase() || options.value === "en";
        })
      );
    }
  }, []);

  const HandleLanguage = (event) => {
    localStorage.setItem("lang", event.value);
    i18n.changeLanguage(event.value);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLang ? selectedLang : l.toLowerCase());
  }, []);

  useEffect(() => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          if (position?.coords?.latitude && position?.coords?.longitude) {
            // console.log(
            //   position?.coords?.latitude,
            //   position?.coords?.longitude
            // );
            localStorage.setItem(
              "location",
              JSON.stringify({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            );
          }
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          setStatus("Unable to retrieve your location");
        }
      );
    }
  }, []);

  function handleSearch(query) {
    setShowSearch(true);
    if (query.length > 2) {
      const searchdata = {
        searchQuery: query,
        language: localStorage.getItem("REACT_APP_LANGUAGE"),
      };
      dispatch(findEventApi(searchdata));
    }
    if (query.length > 2) {
      const searchUser = {
        name: query,
        lang: localStorage.getItem("REACT_APP_LANGUAGE"),
      };
      dispatch(getSearchUserData(searchUser));
    }
  }

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_KEY,
    onPlaceSelected: async (place) => {
      console.log(place);
      sessionStorage.setItem("temp_location_name", place?.formatted_address);
      await sessionStorage.setItem(
        "tempLocation",
        JSON.stringify({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        })
      );
      const data = {
        eventStatus: "APPROVED",
        distance: 300000,
        language: userData?.userdetail?.lang
          ? userData?.userdetail?.language
          : localStorage.getItem("REACT_APP_LANGUAGE"),
        nearBy: true,
        lat: place?.geometry?.location?.lat(),
        long: place?.geometry?.location?.lng(),
      };
      dispatch(nearByEventApi(data));
    },
  });

  useEffect(() => {
    if ((lat, lng)) {
      Geocode.setApiKey(GOOGLE_MAPS_KEY);
      Geocode.setLanguage("en");
      Geocode.setRegion("es");
      Geocode.setLocationType("ROOFTOP");
      Geocode.enableDebug();
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);
        },
        (error) => {}
      );
    }
  }, [lat, lng, GOOGLE_MAPS_KEY]);

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  // useEffect(() => {
  //   const userMobile = Cookies.get("userMobile") || "918917351401";
  //   Cookies.set("userMobile", userMobile);

  //   fetch(`https://shop.exaweb.in:5200/carts/${userMobile}`)
  //     .then((data) => {
  //       setCartCount(data.length);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching cart count", error);
  //     });
  // }, []);

  useEffect(() => {
    const userMobile = "918917351401";
    localStorage.setItem("userMobile", userMobile);

    const fetchCartCount = async () => {
      try {
        if (userMobile) {
          const response = await fetch(
            `https://api.exaweb.in:5200/carts/${userMobile}`
          );
          const data = await response.json();

          setCartCount(data.length);
        }
      } catch (error) {
        console.error("Error fetching cart count:", error);
      }
    };

    fetchCartCount();
  }, []);

  const cls = visible ? "visible" : "hidden";

  // useEffect(() => {
  //   const handleResize = () => {
  //     setShowMenu(false);
  //   };
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  function handleLogout() {
    dispatch(authAction.userLogout());
    localStorage.removeItem("token");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    sessionStorage.removeItem("tempLocation");
    sessionStorage.removeItem("temp_location_name");
    toast("Logout Successful");
  }

  const completeProfileToast = () => {
    toast.warning("Please Complete Your Profile First", { theme: "colored" });
  };

  const handleTruncateText = (text, limit) => {
    return text.length > limit ? `${text.substring(0, limit)}...` : text;
  };

  // const toggleSideBar = () => {
  //   setShow((show) => !show);
  // };

  // const handleNavItemSelect = () => {
  //   toggleSideBar();
  // };

  const handleShopButtonClick = () => {
    //navigate("/shop");
    window.location.href = "https://shop.bharatcalendars.in/";
  };

  const handleCartButtonClick = () => {
    window.location.href = "https://shop.bharatcalendars.in/myCart";
  };

  // const fetchCartData = async () => {
  //   try {
  //     const response = await fetch("https://shop.bharatcalendars.in/myCart");
  //     const cartData = await response.json();
  //     setCartCount(cartData.itemCount);
  //   } catch (error) {
  //     console.error("Error fetching cart data", error);
  //   }
  // };

  const handlePanchangDetailClick = () => {
    ReactGA.event({
      category: "Event Link",
      action: "click",
      label: "Panchang Detail Click",
    });
    //handleNavItemClick();
  };

  const handleEventsClick = () => {
    if (!userData?.newUser || !token) {
      navigate("/event");
      ReactGA.event({
        category: "Navigation",
        action: "Click",
        label: "Events Link",
      });
    } else if (token) {
      completeProfileToast();
    }
    // handleNavItemClick();
  };

  const handleCreateEventsClick = () => {
    ReactGA.event({
      category: "Navigation",
      action: "Click",
      label: "Create Events Link",
    });
    if (token) {
      if (!userData?.newUser) {
        setShowCreate(true);
      } else {
        completeProfileToast();
      }
    } else {
      setShowLogin(true);
    }
    //handleNavItemClick();
  };

  const toggleMobileMenu = () => {
    setExpanded(!expanded);
  };

  // const handleNavItemClick = () => {
  //   setShowMenu(false); // Close the mobile menu when a navigation item is clicked
  // };

  // const handleInputFocus = () => {
  //   setInputFocused(true);
  //   setShowMenu(true);
  // };

  // const handleInputBlur = () => {
  //   setInputFocused(false);
  // };

  // const handleMenuClick = () => {
  //   // Close the mobile menu only if the input is not focused
  //   if (!inputFocused) {
  //     setShowMenu(false);
  //   }
  // };

  return (
    // <>
    // <div
    //   className={`Main-header container-fluid fixed-top ${cls} p-1`}
    //   style={{ padding: 0 }}
    //   onScroll={() => setTops(-70)}
    // >
    //   <div
    //     className="header header-mains-menu d-flex justify-content-between align-items-center px-2"
    //     id="header-scroll"
    //   >
    //     <div className="header">
    //       <div className="row p-0 m-0 d-flex flex-nowrap common justify-content-between align-items-center px-2">
    //         <div className="col-xl-auto w-auto col-md-auto col-sm-auto logo p-0">
    //           <span
    //             onClick={() => {
    //               navigate("/");
    //             }}
    //           >
    //             <img alt="" src={logo} className="logo_img" />
    //           </span>
    //         </div>
    //         <div className="col-xl-auto col-md-auto col-sm-auto p-0 d-none d-xl-block text-center header-search p-0">
    //           <div className="search w-100">
    //             <input
    //               type="text"
    //               name="search"
    //               className="form-control search-box ps-5"
    //               placeholder={t("search")}
    //               id="tags"
    //               onChange={(e) => {
    //                 setSearch(e.target.value);
    //                 handleSearch(e.target.value);
    //               }}
    //             />
    //             <span className="btn search-button searchicon">
    //               <RiSearchLine />
    //             </span>
    //             <span className="vertical" />
    //             <div className="action">
    //               <DropdownButton
    //                 id="dropdown-basic-button"
    //                 title={t(chanSearch)}
    //               >
    //                 <Dropdown.Item onClick={() => setchanSearch(t("Users"))}>
    //                   {t("Users")}
    //                 </Dropdown.Item>
    //                 <Dropdown.Item onClick={() => setchanSearch(t("Events"))}>
    //                   {t("Events")}
    //                 </Dropdown.Item>
    //               </DropdownButton>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-xl-auto col-md-auto col-sm-auto d-none d-xl-block p-0">
    //           <div
    //             className="main-menu text-capitalize d-flex align-items-center ps-0"
    //             id="menu"
    //           >
    //             <li className="menu-li">
    //               <CustomLink
    //                 as={Link}
    //                 to="/horoscope"
    //                 className="browse-event"
    //               >
    //                 {t("Horoscope")}
    //               </CustomLink>
    //             </li>
    //             <li className="menu-li">
    //               <CustomLink
    //                 as={Link}
    //                 to="/panchang"
    //                 className="browse-event"
    //                 onClick={handlePanchangDetailClick}
    //               >
    //                 {t("Panchang")}
    //               </CustomLink>
    //             </li>
    //             <li className="menu-li">
    //               <CustomLink as={Link} to="/kundli" className="browse-event">
    //                 {t("Kundli")}
    //               </CustomLink>
    //             </li>
    //             <li className="menu-li">
    //               <CustomLink as={Link} to="/articles" className="browse-event">
    //                 {t("Articles")}
    //               </CustomLink>
    //             </li>
    //             <li className="menu-li">
    //               <Dropdown>
    //                 <Dropdown.Toggle
    //                   id="dropdown-basic"
    //                   style={{
    //                     background: "transparent",
    //                     border: "0px",
    //                     color: "black",
    //                   }}
    //                 >
    //                   {t("Events")}
    //                 </Dropdown.Toggle>
    //                 <Dropdown.Menu>
    //                   <Dropdown.Item
    //                     // onClick={
    //                     //   () =>
    //                     //   !userData?.newUser || !token
    //                     //     ? navigate("/event")
    //                     //     : token
    //                     //     ? completeProfileToast()
    //                     //     : ""
    //                     // }
    //                     onClick={handleEventsClick}
    //                   >
    //                     {t("Events")}
    //                   </Dropdown.Item>
    //                   <Dropdown.Item
    //                     // onClick={() =>
    //                     //   token
    //                     //     ? !userData?.newUser
    //                     //       ? setShowCreate(true)
    //                     //       : completeProfileToast()
    //                     //     : setShowLogin(true)
    //                     // }
    //                     onClick={handleCreateEventsClick}
    //                   >
    //                     {t("Create Events")}
    //                   </Dropdown.Item>
    //                 </Dropdown.Menu>
    //                 {/* <DropdownButton id="dropdown-basic-button border-0 h-0 w-0"></DropdownButton> */}
    //               </Dropdown>

    //               {/* <CustomLink
    //                 as={Link}
    //                 to="/event"
    //                 onClick={() =>
    //                   userData?.newUser === false || !token
    //                     ? navigate("/event")
    //                     : token
    //                     ? completeProfileToast()
    //                     : ""
    //                 }
    //                 // className="browse-event"
    //               >
    //                 {t("Browse Events")}
    //               </CustomLink>{" "} */}
    //             </li>
    //             <li className="menu-li">
    //               <CustomLink
    //                 as={Link}
    //                 to="/matrimony"
    //                 className="browse-event"
    //               >
    //                 {t("Matrimony")}
    //               </CustomLink>
    //             </li>
    //             <li className="menu-li">
    //               <button
    //                 className="btn btn-warning text-danger fw-bolder me-1"
    //                 onClick={handleShopButtonClick}
    //               >
    //                 Shop
    //                 <FaBagShopping />
    //               </button>
    //             </li>
    //             <li className="menu-li">
    //               <div style={{ position: "relative" }}>
    //                 <button
    //                   className="btn btn-warning text-danger fw-bolder me-1 cart-button"
    //                   onClick={handleCartButtonClick}
    //                 >
    //                   <MdShoppingCart style={{ fontSize: "24px" }} />
    //                 </button>
    //                 <span
    //                   style={{
    //                     position: "absolute",
    //                     top: "-10px",
    //                     right: "0",
    //                     backgroundColor: "red",
    //                     color: "white",
    //                     fontSize: "12px",
    //                     width: "20px",
    //                     height: "20px",
    //                     borderRadius: "50%",
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                   }}
    //                 >
    //                   {cartCount}
    //                 </span>
    //               </div>
    //             </li>

    //             <li className="menu-li">
    //               {!token && !userData?.userdetail?.imageURL ? (
    //                 <CustomLink
    //                   to=""
    //                   className="signin-btn"
    //                   onClick={() => handleShowLogin()}
    //                 >
    //                   {t("Sign In")}
    //                 </CustomLink>
    //               ) : (
    //                 <ProfileDropdown handleLogout={() => handleLogout()} />
    //               )}
    //             </li>
    //           </div>
    //         </div>
    //         <div className="col-xl-auto col-md-auto col-sm-auto w-auto d-none d-md-flex d-xl-flex p-0 me-1">
    //           <span className="location">
    //             <BiCurrentLocation />
    //           </span>
    //           <input
    //             className="form-control search-box ps-5 loc w-75"
    //             ref={ref}
    //             type="text"
    //             defaultValue={
    //               sessionStorage.getItem("temp_location_name")
    //                 ? sessionStorage.getItem("temp_location_name")
    //                 : null
    //             }
    //             placeholder={handleTruncateText(t("Enter_Location"), limit)}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //     <div className="langbox d-none d-md-block d-xl-block col-md-auto p-1">
    //       {l && (
    //         <Select
    //           options={fOptions}
    //           classNamePrefix={"selectbox"}
    //           onChange={HandleLanguage}
    //           defaultValue={
    //             selectedLang === null && l === "MS"
    //               ? options[0]
    //               : selectedLang
    //               ? options[
    //                   options.findIndex((event) => event.value === selectedLang)
    //                 ]
    //               : options[
    //                   options.findIndex(
    //                     (event) => event.value === l.toLowerCase()
    //                   )
    //                 ]
    //           }
    //         />
    //       )}
    //     </div>
    //     <div className="col-sm-auto d-xl-none d-flex justify-content-end align-items-center">
    //       <div className="me-4">
    //         <FaBars
    //           size={20}
    //           onClick={toggleSideBar}
    //           style={{ cursor: "pointer" }}
    //         />
    //       </div>
    //       <Sidemenudrawer
    //         show={show}
    //         token={token}
    //         HandleLanguage={HandleLanguage}
    //         setchanSearch={(data) => setchanSearch(data)}
    //         chanSearch={chanSearch}
    //         // selectedLang={selectedLang}
    //         setSearch={(e) => setSearch(e)}
    //         handleSearch={(e) => handleSearch(e)}
    //         handleShowCreate={() => handleShowCreate()}
    //         handleLogout={() => handleLogout()}
    //         handleShowLogin={() => handleShowLogin()}
    //         options={fOptions}
    //         search={search}
    //         all={options}
    //         handleNavItemSelect={handleNavItemSelect}
    //       />
    //     </div>
    //   </div>
    //   <LoginModal
    //     show={showLogin}
    //     handleCloseLogin={() => handleCloseLogin()}
    //     lng={lng}
    //     lat={lat}
    //   />
    //   {search.length > 2 && (
    //     <Search
    //       show={showsearch}
    //       close={handleCloseSearch}
    //       setSearch={() => setSearch("")}
    //       searchBy={chanSearch}
    //     />
    //   )}
    //   {showCreate && userData?.isAuthenticated === true && (
    //     <NewEvent show={showCreate} close={handleCloseCreate} />
    //   )}
    // </div>
    // <>
    //   <Navbar
    //     expand="lg"
    //     bg="light"
    //     variant="light"
    //     sticky="top"
    //     className="p-1"
    //   >
    //     <Container fluid>
    //       <Navbar.Brand>
    //         <img
    //           alt="logo"
    //           src={logo}
    //           className="logo_img"
    //           style={{ width: "100%", height: "130px" }}
    //           onClick={() => {
    //             navigate("/");
    //           }}
    //         />
    //       </Navbar.Brand>
    //       <Navbar.Toggle aria-controls="navbar-nav" />
    //       <Navbar.Collapse id="navbar-nav" className="justify-content-between">
    //         <Nav className="me-auto align-items-center">
    //           <Row className="gx-2 justify-content-between">
    //             {/* First Line */}
    //             <Col xs={2}>
    //               <Nav.Item className="me-2 text-capitalize">
    //                 <CustomLink
    //                   as={Link}
    //                   to="/horoscope"
    //                   className="browse-event"
    //                 >
    //                   {t("Horoscope")}
    //                 </CustomLink>
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={2}>
    //               <Nav.Item className="me-2 text-capitalize">
    //                 <CustomLink
    //                   as={Link}
    //                   to="/panchang"
    //                   className="browse-event"
    //                   onClick={handlePanchangDetailClick}
    //                 >
    //                   {t("Panchang")}
    //                 </CustomLink>
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={2}>
    //               <Nav.Item className="me-2 text-capitalize">
    //                 <CustomLink as={Link} to="/kundli" className="browse-event">
    //                   {t("Kundli")}
    //                 </CustomLink>
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={2}>
    //               <Nav.Item className="me-2 text-capitalize">
    //                 <CustomLink as={Link} to="/kundli" className="browse-event">
    //                   {t("Kundli")}
    //                 </CustomLink>
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={2}>
    //               <Nav.Item className="me-2 text-capitalize">
    //                 <button
    //                   className="btn btn-warning text-danger fw-bolder me-1"
    //                   onClick={handleShopButtonClick}
    //                 >
    //                   Shop
    //                   <FaBagShopping />
    //                 </button>
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={2}>
    //               <Nav.Item className="me-2 text-capitalize">
    //                 <Button
    //                   variant="warning"
    //                   className="text-danger fw-bolder cart-button"
    //                   onClick={handleCartButtonClick}
    //                 >
    //                   <MdShoppingCart style={{ fontSize: "24px" }} />
    //                   <span className="badge bg-danger ms-1">{cartCount}</span>
    //                 </Button>
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={2}>
    //               <Nav.Item className="me-2 text-capitalize">
    //                 {!token && !userData?.userdetail?.imageURL ? (
    //                   <CustomLink
    //                     to=""
    //                     className="signin-btn"
    //                     onClick={() => handleShowLogin()}
    //                   >
    //                     {t("Sign In")}
    //                   </CustomLink>
    //                 ) : (
    //                   <ProfileDropdown handleLogout={() => handleLogout()} />
    //                 )}
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={2}>
    //               <Nav.Item className="me-2 text-capitalize">
    //                 {l && (
    //                   <Select
    //                     options={fOptions}
    //                     classNamePrefix={"selectbox"}
    //                     onChange={HandleLanguage}
    //                     defaultValue={
    //                       selectedLang === null && l === "MS"
    //                         ? options[0]
    //                         : selectedLang
    //                         ? options[
    //                             options.findIndex(
    //                               (event) => event.value === selectedLang
    //                             )
    //                           ]
    //                         : options[
    //                             options.findIndex(
    //                               (event) => event.value === l.toLowerCase()
    //                             )
    //                           ]
    //                     }
    //                   />
    //                 )}
    //               </Nav.Item>
    //             </Col>
    //           </Row>
    //         </Nav>

    //         <Nav className="ms-auto align-items-center">
    //           <Row className="gx-2 justify-content-between">
    //             <Col xs={2}>
    //               <Nav.Item className="me-2 text-capitalize">
    //                 <div className="search w-100">
    //                   <input
    //                     type="text"
    //                     name="search"
    //                     className="form-control search-box ps-5"
    //                     placeholder={t("search")}
    //                     id="tags"
    //                     onChange={(e) => {
    //                       setSearch(e.target.value);
    //                       handleSearch(e.target.value);
    //                     }}
    //                   />
    //                   <span className="btn search-button searchicon">
    //                     <RiSearchLine />
    //                   </span>
    //                   <span className="vertical" />
    //                   <div className="action">
    //                     <DropdownButton
    //                       id="dropdown-basic-button"
    //                       title={t(chanSearch)}
    //                     >
    //                       <Dropdown.Item
    //                         onClick={() => setchanSearch(t("Users"))}
    //                       >
    //                         {t("Users")}
    //                       </Dropdown.Item>
    //                       <Dropdown.Item
    //                         onClick={() => setchanSearch(t("Events"))}
    //                       >
    //                         {t("Events")}
    //                       </Dropdown.Item>
    //                     </DropdownButton>
    //                   </div>
    //                 </div>
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={2}>
    //               <Nav.Item className="me-2">
    //                 <CustomLink
    //                   as={Link}
    //                   to="/articles"
    //                   className="browse-event"
    //                 >
    //                   {t("Articles")}
    //                 </CustomLink>
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={3}>
    //               <Nav.Item className="me-2">
    //                 <Dropdown>
    //                   <Dropdown.Toggle
    //                     id="dropdown-basic"
    //                     style={{
    //                       background: "transparent",
    //                       border: "0px",
    //                       color: "black",
    //                     }}
    //                   >
    //                     {t("Events")}
    //                   </Dropdown.Toggle>
    //                   <Dropdown.Menu>
    //                     <Dropdown.Item
    //                       // onClick={
    //                       //   () =>
    //                       //   !userData?.newUser || !token
    //                       //     ? navigate("/event")
    //                       //     : token
    //                       //     ? completeProfileToast()
    //                       //     : ""
    //                       // }
    //                       onClick={handleEventsClick}
    //                     >
    //                       {t("Events")}
    //                     </Dropdown.Item>
    //                     <Dropdown.Item
    //                       // onClick={() =>
    //                       //   token
    //                       //     ? !userData?.newUser
    //                       //       ? setShowCreate(true)
    //                       //       : completeProfileToast()
    //                       //     : setShowLogin(true)
    //                       // }
    //                       onClick={handleCreateEventsClick}
    //                     >
    //                       {t("Create Events")}
    //                     </Dropdown.Item>
    //                   </Dropdown.Menu>
    //                   {/* <DropdownButton id="dropdown-basic-button border-0 h-0 w-0"></DropdownButton> */}
    //                 </Dropdown>

    //                 {/* <CustomLink
    //                 as={Link}
    //                 to="/event"
    //                 onClick={() =>
    //                   userData?.newUser === false || !token
    //                     ? navigate("/event")
    //                     : token
    //                     ? completeProfileToast()
    //                     : ""
    //                 }
    //                 // className="browse-event"
    //               >
    //                 {t("Browse Events")}
    //               </CustomLink>{" "} */}
    //               </Nav.Item>
    //             </Col>
    //             <Col xs={3}>
    //               <Nav.Item className="me-2">
    //                 <CustomLink as={Link} to="/matrimony" className="nav-link">
    //                   {t("Matrimony")}
    //                 </CustomLink>
    //               </Nav.Item>
    //             </Col>
    //           </Row>
    //           {/* SECOND LINE  */}
    //         </Nav>
    //       </Navbar.Collapse>
    //     </Container>
    //   </Navbar>
    // </>
    //</>

    <>
      <Navbar
        expand="lg"
        sticky="top"
        style={{ backgroundColor: "white",borderBottom:'1px solid black' }}
      >
        <Container fluid className="align-items-center">
          <Navbar.Brand>
            <img
              alt="logo"
              src={logo}
              className="logo_img"
              style={{ width: "auto", height: "78px", cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            />
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          {/* <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggleMenu}
          >
            {showMenu ? <FaTimes /> : <FaBars />}
          </Navbar.Toggle> */}

          <div
            className="d-md-none"
            style={{ cursor: "pointer" }}
            onClick={() => setShowMenu(!showMenu)}
          >
            {/* <button type="button" className="btn btn-link" onClick={handleToggleMenu}> */}
            {showMenu ? (
              <FaTimes className="text-dark" />
            ) : (
              <FaBars className="text-dark" />
            )}
            {/* </button> */}
          </div>

          <div
            className={`mobile-menu ${showMenu ? "show" : "hide-on-mobile"}`}
            //onClick={handleMenuClick}
          >
            <Nav className="flex-column align-items-center">
              <Nav.Item>
                <div className="langbox p-1 mb-3">
                  {l && (
                    <Select
                      options={fOptions}
                      classNamePrefix={"selectbox"}
                      onChange={HandleLanguage}
                      defaultValue={
                        selectedLang === null && l === "MS"
                          ? options[0]
                          : selectedLang
                          ? options[
                              options.findIndex(
                                (event) => event.value === selectedLang
                              )
                            ]
                          : options[
                              options.findIndex(
                                (event) => event.value === l.toLowerCase()
                              )
                            ]
                      }
                      style={{ height: "22px", fontSize: "14px" }}
                    />
                  )}
                </div>
              </Nav.Item>
              <Nav.Item
                className="mb-3 ms-3"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="location w-100 position-relative">
                  <div className="input-group rounded-3">
                    <span className="input-group-text location">
                      <BiCurrentLocation />
                    </span>
                    <input
                      className="form-control search-box loc w-50"
                      ref={ref}
                      type="text"
                      defaultValue={
                        sessionStorage.getItem("temp_location_name")
                          ? sessionStorage.getItem("temp_location_name")
                          : null
                      }
                      placeholder={handleTruncateText(
                        t("Enter_Location"),
                        limit
                      )}
                      style={{ textIndent: "0" }}
                      // onFocus={handleInputFocus}
                      // onBlur={handleInputBlur}
                    />
                  </div>
                </div>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <CustomLink
                  as={Link}
                  to="/horoscope"
                  className="custom-link browse-event mb-2"
                  // onClick={handleNavItemClick}
                >
                  {t("Horoscope")}
                </CustomLink>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <CustomLink
                  as={Link}
                  to="/panchang"
                  className="custom-link browse-event mb-2"
                  onClick={handlePanchangDetailClick}
                >
                  {t("Panchang")}
                </CustomLink>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <CustomLink
                  as={Link}
                  to="/kundli"
                  className="custom-link browse-event mb-2"
                  // onClick={handleNavItemClick}
                >
                  {t("Kundli")}
                </CustomLink>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      background: "transparent",
                      border: "0px",
                      color: "black",
                    }}
                  >
                    {t("Events")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      // onClick={
                      //   () =>
                      //   !userData?.newUser || !token
                      //     ? navigate("/event")
                      //     : token
                      //     ? completeProfileToast()
                      //     : ""
                      // }
                      onClick={handleEventsClick}
                    >
                      {t("Events")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      // onClick={() =>
                      //   token
                      //     ? !userData?.newUser
                      //       ? setShowCreate(true)
                      //       : completeProfileToast()
                      //     : setShowLogin(true)
                      // }
                      onClick={handleCreateEventsClick}
                    >
                      {t("Create Events")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                  {/* <DropdownButton id="dropdown-basic-button border-0 h-0 w-0"></DropdownButton> */}
                </Dropdown>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <CustomLink
                  as={Link}
                  to="/articles"
                  className="custom-link browse-event"
                  // onClick={handleNavItemClick}
                >
                  {t(selectedTranslation.labels.articles)}
                </CustomLink>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <CustomLink
                  as={Link}
                  to="/matrimony"
                  className="custom-link browse-event"
                  // onClick={handleNavItemClick}
                >
                  {t(selectedTranslation.labels.matrimony)}
                </CustomLink>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <Link
                  className="me-1 text-decoration-none text-dark"
                  to="/festivals"
                >
                  {t(selectedTranslation.labels.festivals)}
                </Link>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <CustomLink
                  as={Link}
                  to="/holidays"
                  className="custom-link browse-event"
                >
                  {t(selectedTranslation.labels.holidays)}
                </CustomLink>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <div>
                  <Link
                    to="/buykundli"
                    className="btn btn-warning text-danger fw-bolder me-1"
                  >
                    {t(selectedTranslation.labels.buykundli)}
                  </Link>
                </div>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <div>
                  <button
                    className="btn btn-warning text-danger fw-bolder"
                    onClick={handleShopButtonClick}
                  >
                    {t(selectedTranslation.labels.shop)}

                    <FaBagShopping />
                  </button>
                </div>
              </Nav.Item>

              <Nav.Item className="mb-2">
                <div>
                  <button
                    className="btn btn-warning text-danger fw-bolder cart-button"
                    onClick={handleCartButtonClick}
                  >
                    <MdShoppingCart style={{ fontSize: "24px" }} />
                    {cartCount}
                  </button>
                </div>
              </Nav.Item>

              <Nav.Item className="mb-2">
                {!token && !userData?.userdetail?.imageURL ? (
                  <CustomLink
                    to=""
                    className="signin-btn btn btn-warning text-danger"
                    onClick={() => handleShowLogin()}
                  >
                    {t("Sign In")}
                  </CustomLink>
                ) : (
                  <ProfileDropdown handleLogout={() => handleLogout()} />
                )}
              </Nav.Item>

              <Nav.Item className="mt-3 mb-3">
                <div className="search w-75 ms-4 position-relative">
                  <div className="input-group">
                    <span className="input-group-text search-button searchicon">
                      <RiSearchLine />
                    </span>
                    <input
                      type="text"
                      name="search"
                      className="form-control search-box p-2 rounded-2"
                      placeholder={t("search")}
                      id="tags"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        handleSearch(e.target.value);
                      }}
                    />

                    <DropdownButton
                      id="dropdown-basic-button"
                      variant="light"
                      title={t(chanSearch)}
                      className="position-absolute end-0 top-50 translate-middle-y"
                      style={{ zIndex: "999" }}
                    >
                      <Dropdown.Item onClick={() => setchanSearch(t("Users"))}>
                        {t("Users")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setchanSearch(t("Events"))}>
                        {t("Events")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </Nav.Item>
            </Nav>
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-2">
              <Nav.Item className="m-4">
                <div className="search w-100 position-relative">
                  <div className="input-group">
                    <span className="input-group-text search-button searchicon">
                      <RiSearchLine />
                    </span>
                    <input
                      type="text"
                      name="search"
                      className="form-control search-box p-2 rounded-2 border-2"
                      placeholder={t("search")}
                      id="tags"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        handleSearch(e.target.value);
                      }}
                    />

                    <DropdownButton
                      id="dropdown-basic-button"
                      variant="light"
                      title={t(chanSearch)}
                      className="position-absolute end-0 top-50 pe-1 translate-middle-y"
                      style={{ zIndex: "999" }}
                    >
                      <Dropdown.Item
                        className="mt-1"
                        onClick={() => setchanSearch(t("Users"))}
                      >
                        {t("Users")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="mt-1"
                        onClick={() => setchanSearch(t("Events"))}
                      >
                        {t("Events")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </Nav.Item>

              <Nav.Item className="ms-1 mt-3 me-lg-5">
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <CustomLink
                    as={Link}
                    to="/horoscope"
                    className="custom-link browse-event me-5 mb-2"
                  >
                    {t("Horoscope")}
                  </CustomLink>

                  <CustomLink
                    as={Link}
                    to="/panchang"
                    className="custom-link browse-event me-5 mb-2"
                    onClick={handlePanchangDetailClick}
                  >
                    {t("Panchang")}
                  </CustomLink>

                  <CustomLink
                    as={Link}
                    to="/kundli"
                    className="custom-link browse-event me-5 mb-2"
                  >
                    {t("Kundli")}
                  </CustomLink>

                  <CustomLink
                    as={Link}
                    className="custom-link browse-event me-5 mb-2"
                    to="/festivals"
                  >
                    {t(selectedTranslation.labels.festivals)}
                  </CustomLink>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <Dropdown className="me-5">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      style={{
                        background: "transparent",
                        border: "0px",
                        color: "black",
                      }}
                    >
                      {t("Events")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        // onClick={
                        //   () =>
                        //   !userData?.newUser || !token
                        //     ? navigate("/event")
                        //     : token
                        //     ? completeProfileToast()
                        //     : ""
                        // }
                        onClick={handleEventsClick}
                      >
                        {t("Events")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        // onClick={() =>
                        //   token
                        //     ? !userData?.newUser
                        //       ? setShowCreate(true)
                        //       : completeProfileToast()
                        //     : setShowLogin(true)
                        // }
                        onClick={handleCreateEventsClick}
                      >
                        {t("Create Events")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                    {/* <DropdownButton id="dropdown-basic-button border-0 h-0 w-0"></DropdownButton> */}
                  </Dropdown>

                  {/* <CustomLink
                    as={Link}
                    to="/event"
                    onClick={() =>
                      userData?.newUser === false || !token
                        ? navigate("/event")
                        : token
                        ? completeProfileToast()
                        : ""
                    }
                    // className="browse-event"
                  >
                    {t("Browse Events")}
                  </CustomLink>{" "} */}

                  <CustomLink
                    as={Link}
                    to="/articles"
                    className="custom-link browse-event me-5"
                  >
                    {t(selectedTranslation.labels.articles)}
                  </CustomLink>

                  <CustomLink
                    as={Link}
                    to="/matrimony"
                    className="custom-link browse-event me-5"
                  >
                    {t(selectedTranslation.labels.matrimony)}
                  </CustomLink>

                  <CustomLink
                    as={Link}
                    to="/holidays"
                    className="custom-link browse-event me-5"
                  >
                    {t(selectedTranslation.labels.holidays)}
                  </CustomLink>
                </div>
              </Nav.Item>
              <Nav.Item className="justify-content-end mt-2">
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <div>
                    <Link
                      to="/buykundli"
                      className="btn btn-warning text-danger fw-bolder me-1"
                      // onClick={() => navigate("/buykundli")}
                    >
                      {t(selectedTranslation.labels.buykundli)}
                    </Link>
                  </div>
                  <div>
                    <button
                      className="btn btn-warning text-danger fw-bolder me-1"
                      onClick={handleShopButtonClick}
                    >
                      {t(selectedTranslation.labels.shop)}

                      <FaBagShopping />
                    </button>
                  </div>
                  <div style={{ position: "relative" }}>
                    <button
                      className="btn btn-warning text-danger fw-bolder me-1 cart-button"
                      onClick={handleCartButtonClick}
                    >
                      <MdShoppingCart style={{ fontSize: "24px" }} />
                    </button>
                    <span
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "0",
                        backgroundColor: "red",
                        color: "white",
                        fontSize: "12px",
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {cartCount}
                    </span>
                  </div>
                  <div>
                    {!token && !userData?.userdetail?.imageURL ? (
                      <CustomLink
                        to=""
                        className="signin-btn btn btn-warning text-danger"
                        onClick={() => handleShowLogin()}
                      >
                        {t("Sign In")}
                      </CustomLink>
                    ) : (
                      <ProfileDropdown handleLogout={() => handleLogout()} />
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="location w-100 position-relative">
                    <div className="input-group rounded-3">
                      <span className="input-group-text location">
                        <BiCurrentLocation />
                      </span>
                      <input
                        className="form-control search-box loc w-50"
                        ref={ref}
                        type="text"
                        defaultValue={
                          sessionStorage.getItem("temp_location_name")
                            ? sessionStorage.getItem("temp_location_name")
                            : null
                        }
                        placeholder={handleTruncateText(
                          t("Enter_Location"),
                          limit
                        )}
                        style={{ textIndent: "0" }}
                      />
                    </div>
                  </div>
                  <div className="langbox d-none d-md-block d-lg-block col-md-auto p-1">
                    {l && (
                      <Select
                        options={fOptions}
                        classNamePrefix={"selectbox"}
                        onChange={HandleLanguage}
                        defaultValue={
                          selectedLang === null && l === "MS"
                            ? options[0]
                            : selectedLang
                            ? options[
                                options.findIndex(
                                  (event) => event.value === selectedLang
                                )
                              ]
                            : options[
                                options.findIndex(
                                  (event) => event.value === l.toLowerCase()
                                )
                              ]
                        }
                      />
                    )}
                  </div>
                </div>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div>
        <LoginModal
          show={showLogin}
          handleCloseLogin={() => handleCloseLogin()}
          lng={lng}
          lat={lat}
        />
        {search.length > 2 && (
          <Search
            show={showsearch}
            close={handleCloseSearch}
            setSearch={() => setSearch("")}
            searchBy={chanSearch}
          />
        )}
        {showCreate && userData?.isAuthenticated === true && (
          <NewEvent show={showCreate} close={handleCloseCreate} />
        )}
      </div>
    </>
  );
}

export default Header;
