import Information from "./information/information";
import "./profile.scss";
import { useState } from "react";
import ProfileTabs from "../../Components/ProfileTabs/profileTabs";
import Event from "./userEvents/events";
import { useTranslation } from "react-i18next";

function Profile() {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  return (
    <>
        <div className="container page-main-contain">
          {/* <div className="row">
            <div className="col-12">
              <h1 className="profile-title mt-5"> {t("Manage_Your_Profile")}</h1>
            </div>
          </div> */}
          <div className="row page-main-contain">
            <div className="col-lg-3 col-sm-1 col-md-2 mb-3">
                <ProfileTabs
                  tabItem={[t("Personal_Info"), t("Events")]}
                  activeTab={tab}
                  selectTab={(val) => setTab(val)}
                />
            </div>

            <div
              className="col-lg-9 col-sm-12 col-md-9"
              style={{ marginTop: "-5px", paddingLeft: "30px" }}
            >
              <div className="tab-content">
                <div
                  role="tabpanel"
                  className="tab-pane in active"
                  id="profile"
                >
                  {parseInt(tab) === 0 && (
                    <div className="row accumn-info">
                      <div className="col-lg-12 col-sm-12 col-md-12">
                        <h1 className="profile-title profile-title-text page-header">
                          {t("Personal_Information")}
                        </h1>
                      </div>
                      <div className="col-lg-12 col-sm-12 col-md-12">
                        <Information />
                      </div>
                    </div>
                  )}
                </div>
                {parseInt(tab) === 1 && (
                  <div className="row accumn-info" >
                    <div className="col-lg-12 col-sm-12 col-md-12">
                      <h1 className="profile-title profile-title-text page-header">
                        {t("Events")}
                      </h1>
                    </div>
                    <div className="col-12">
                      <Event />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default Profile;
