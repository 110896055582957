import React from "react";
import "./KundaliShopStyles.scss";
import logo from "../../img/logo/CalenderIcon.jpg";
import kundaliBook from "../../img/kundalinipdf.png";
import { LuIndianRupee } from "react-icons/lu";
import customercare from "../../img/customercare.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Translations from "../Translations/Translations.json";
import { useTranslation } from "react-i18next";

function KundaliBuyIndex() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const storedLanguage = localStorage.getItem("lang") || "en";
  const selectedTranslation = Translations[storedLanguage] || Translations.en;

  return (
    <div className="container px-3">
      <Helmet>
        <title>
          Unlock Your Cosmic Blueprint - Buy Kundali with Bharat Calendars
        </title>
        <meta
          name="description"
          content="Embark on a journey of self-discovery and empowerment with our Buy Kundali feature. Gain clarity, make informed decisions, and harness the cosmic wisdom encoded in your unique Kundali. Order now to receive a beautifully crafted colored Kundli, detailed insights on doshas and remedies, success secrets, and more. Your cosmic destiny awaits!"
        />
      </Helmet>
      <div className="kundalishop-section mt-3 mb-4">
        <div className="row gx-5">
          <div className="col-md-9 shadow">
            <div className="heading-div mb-2">
              <strong className="fs-3">
                {t(selectedTranslation.labels.title)}
              </strong>
            </div>
            <div className="row product-section mt-3 mb-3">
              <div className="col-md-4 col-12 text-center mt-5">
                <div className="product-image">
                  <img
                    src={kundaliBook}
                    alt="Kundali Book - Beautiful colored Kundli with more than 25 pages describing life in detail."
                    width="180px"
                    height="220px"
                  />
                </div>
                <div
                  className="btn order-btn mt-3 p-3 w-50"
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={() => navigate("/checkout")}
                >
                  {t(selectedTranslation.labels.order)}
                </div>
              </div>
              <div className="col-md-8 col-12">
                <ul className="product-details-list">
                  <li className="detail text-dark fs-5">
                    <img src={logo} alt="bullet" width="30px" height="30px" />
                    <strong>{t(selectedTranslation.labels.heading1)}</strong>
                    <p className="text-wrap">
                      {t(selectedTranslation.labels.detail1)}
                    </p>
                  </li>
                  <li className="detail text-dark fs-5">
                    <img src={logo} alt="bullet" width="30px" height="30px" />
                    <strong>{t(selectedTranslation.labels.heading2)}</strong>
                    <p className="text-wrap">
                      {t(selectedTranslation.labels.detail2)}
                    </p>
                  </li>
                  <li className="detail text-dark fs-5">
                    <img src={logo} alt="bullet" width="30px" height="30px" />
                    <strong>{t(selectedTranslation.labels.heading3)}</strong>
                    <p className="text-wrap">
                      {t(selectedTranslation.labels.detail3)}
                    </p>
                  </li>
                  <li className="detail text-dark fs-5">
                    <img src={logo} alt="bullet" width="30px" height="30px" />
                    <strong>{t(selectedTranslation.labels.heading4)}</strong>
                    <p className="text-wrap">
                      {t(selectedTranslation.labels.detail4)}
                    </p>
                  </li>
                  <li className="detail text-dark fs-5">
                    <img src={logo} alt="bullet" width="30px" height="30px" />
                    <strong>{t(selectedTranslation.labels.heading5)}</strong>
                    <p className="text-wrap">
                      {t(selectedTranslation.labels.detail5)}
                    </p>
                  </li>
                  <li className="detail text-dark fs-5">
                    <img src={logo} alt="bullet" width="30px" height="30px" />
                    <strong>{t(selectedTranslation.labels.heading6)}</strong>
                    <p className="text-wrap">
                      {t(selectedTranslation.labels.detail6)}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="col-md-3 col-12 text-center shadow"
            style={{ backgroundColor: "wheat", color: "brown" }}
          >
            <div className="discounted-price fs-2">
              <LuIndianRupee /> 99
            </div>
            <div className="actual-price fs-6 m-2">
              <strong> {t(selectedTranslation.labels.price)}:</strong>{" "}
              <span className="text text-decoration-line-through">
                <LuIndianRupee /> 599
              </span>
            </div>
            <div className="discount-offer">
              <p className="text-danger fs-3">
                {" "}
                {t(selectedTranslation.labels.save)} : ₹ 500 (83.47%)
              </p>
              <span> {t(selectedTranslation.labels.inclusive)}</span>
            </div>
            <div
              className="btn order-btn mt-3 p-2 w-50"
              style={{ backgroundColor: "blue", color: "white" }}
              onClick={() => navigate("/checkout")}
            >
              {t(selectedTranslation.labels.order)}
            </div>
            <p> {t(selectedTranslation.labels.delivery)}</p>
            <hr className="mt-3 mb-3 text-warning" />
            <div className="customer-care">
              <img
                src={customercare}
                alt="customercare"
                width="80px"
                height="80px"
                style={{ borderRadius: "100%" }}
              />
              <p className="care-heading fs-3">
                {" "}
                {t(selectedTranslation.labels.customer)}
              </p>
              <div className="contact-information">
                <b className="fs-4">+91 8139975010</b>
                <p>(9AM to 6PM IST)</p>
              </div>
              <div className="customer-care-email mt-2">
                customercare@bharatcalendars.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KundaliBuyIndex;
