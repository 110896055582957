import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        description: '',
    });
    const [captchaValue, setCaptchaValue] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!captchaValue) {
                toast.error('Please verify captcha to submit the form');
                return;
            }

            const res = await axios.post(`https://debug.exaweb.in:3004/api/account/contactEmail`, formData);
            if (res.status === 200) {
                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    subject: '',
                    description: '',
                });
            }

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'start', textAlign: 'justify', fontSize: '1.2rem', padding: '0.5rem' }}>
            <Container>
                <h3 style={{ color: "#f16334", fontFamily: "fantasy", textAlign: "center", fontSize: "2rem", margin: '1rem 0rem' }}>Contact Us</h3>
                <Row className="justify-content-center">
                    <Col md={5} style={{ border: '1px solid #f16334', padding: '1rem', borderRadius: '10px', gap: '1rem' }}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
                            </Form.Group>

                            <Form.Group controlId="formEmail" className='mt-2'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} required />
                            </Form.Group>

                            <Form.Group controlId="formPhoneNumber" className='mt-2'>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" name="phoneNumber" placeholder="Enter your phone number" value={formData.phoneNumber} onChange={handleChange} required />
                            </Form.Group>

                            <Form.Group controlId="formSubject" className='mt-2'>
                                <Form.Label>Subject</Form.Label>
                                <Form.Control type="text" name="subject" placeholder="Enter subject" value={formData.subject} onChange={handleChange} required />
                            </Form.Group>

                            <Form.Group controlId="formDescription" className='mt-2'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={4} name="description" placeholder="Enter description" value={formData.description} onChange={handleChange} required />
                            </Form.Group>

                            <div className="text-center">
                                <Form.Group controlId="formCaptcha" className='my-3'>
                                    <ReCAPTCHA
                                        sitekey="6Le27NUpAAAAAIGMdi_4CLWBlnKfe8WZUBuut-X1"
                                        onChange={handleCaptchaChange}
                                    />
                                </Form.Group>
                            </div>

                            <div className="text-center">
                                <Button variant="primary" type="submit" disabled={!captchaValue}>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ContactUs;
