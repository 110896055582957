import React, { useContext } from "react";
// import { IoIosArrowDroprightCircle } from "react-icons/io";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import CategoryItems from "./CategoryItems";
import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
import "./ProductCategories.scss";

function ProductCategories() {
  const { loading, error, categories, shopData } =
    useContext(allShopItemsContext);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [categoryItems, setCategoryItems] = useState([]);
  //const history = useHistory();

  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    // console.log("Selected Category:", category._id);
    // setSelectedCategory(category._id);
    const filteredItems = shopData.filter(
      (item) => item.category === category.name
    );
    // console.log(filteredItems);
    // setCategoryItems(filteredItems);
    navigate(`/category/${category._id}`, { state: filteredItems });
  };

  if (loading) {
    return (
      <div>
        <h3 className="text-center text-danger">Loading...</h3>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const getImageUrl = (imagePath) => {
    const baseUrl = "https://shop.bharatcalendars.in";
    const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");
    return `${baseUrl}/${normalizedPath}`;
  };

  return (
    <>
      <div className="container mt-4 text-dark text-center">
        <div className="row g-4">
          <div>
            <p
              style={{
                fontSize: "35px",
                fontFamily: "montserrat-font",
                fontWeight: "900",
              }}
              className="cat-title"
            >
              Shop By Category
            </p>
          </div>
          <div
            className="d-flex justify-content-evenly item-container"
            style={{
              borderRadius: "18px",
              padding: "9px",
              backgroundColor: "wheat",
            }}
          >
            {categories.map((category) => (
              <div
                className={`card rounded bg-dark`}
                key={category._id}
                style={{ width: "180px", height: "145px", margin: "10px" }}
                onClick={() => handleCategoryClick(category)}
              >
                <div className="card-img-top">
                  <img
                    src={getImageUrl(category.logo)}
                    alt={category.name}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      margin: "5px",
                    }}
                  />
                  <p className="item-name text-light">{category.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCategories;
