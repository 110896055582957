import "./footer.scss";
import { ImFacebook } from "react-icons/im";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import moment from "moment";
import { useTranslation } from "react-i18next";
import MobileFrame from "./MobileFrame";
import BharatCalender from "../../img/footer/Bharat Calendar.png";
import { ReactComponent as RightArrow } from "../../img/icons/arrow-right-2.svg";
import { Link } from "react-router-dom";

function Footer() {
  const currentTime = new Date();
  const { t } = useTranslation();
  const origin = window.location.origin;

  return (
    <div className="footer-wrapper">
      <div className="container">
        <MobileFrame />
      </div>
      <div style={{ background: "#483f50" }}>
        <div
          style={{
            position: "relative",
            top: -55,
          }}
        >
          <div className="row g-0">
            <div className="col-md-12">
              <footer className="footer">
                <div className="container">
                  <div className="row gy-4 pb-4">
                    <div className="col-6 col-lg-3 col-md-3">
                      <div className="footer-title">{t("QUICK_LINKS")}</div>
                      <div className="footer-links">
                        <a href="/horoscope">{t("Horoscope")}</a>
                        <a href="/panchang">{t("Panchang")}</a>
                        <a href="/kundli">{t("Kundli")}</a>
                        <a href="/event">{t("Browse Events")}</a>
                        <a href="#">{t("Sign Up")}</a>
                      </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-3">
                      <div className="footer-title">{t("Information")}</div>
                      <div className="footer-links">
                        <Link to="/faq">
                          {t("FAQs")}
                        </Link>
                        <a href="#">{t("Blog")}</a>
                        <a href="#">{t("Support")}</a>
                      </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-3">
                      <div className="footer-title">{t("Company")}</div>
                      <div className="footer-links">
                        <Link to="/aboutus">{t("About_us")}</Link>
                        <a href="#">{t("Careers")}</a>
                        <Link to="/contact-us">{t("Contact_us")}</Link>
                        <a href="#">{t("Media")}</a>
                      </div>
                    </div>
                    <div className="col-6 col-lg-3 col-md-3">
                      <div className="footer-title">{t("Subscribe")}</div>
                      <div
                        className="input-group mb-3"
                        style={{ height: "60px" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email address"
                          aria-label="Email address"
                          aria-describedby="subscribe-button"
                        />
                        <button
                          className="btn text-white px-3"
                          style={{ background: "#EB7625" }}
                          type="button"
                          id="subscribe-button"
                        >
                          <RightArrow />
                        </button>
                      </div>
                      <p
                        className="montserrat-font fw-normal"
                        style={{ fontSize: "14px", textAlign: "justify" }}
                      >
                        Hello, we are Bharat Calendars. Our goal is to translate
                        the positive effects from revolutionizing how companies
                        engage with their clients & their team.
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex flex-lg-row flex-column gap-3 justify-content-between align-items-center">
                    <div style={{ width: "150px", padding: "0px" }}>
                      <img src={BharatCalender} alt="" className="img-fluid" />
                    </div>
                    <div className="footer-links d-flex justify-content-between gap-5">
                      <a href="#">{t("Terms")}</a>
                      <a href={origin + "/privacy.html"}>{t("Privacy")}</a>
                      <a href="#">{t("Cookies")}</a>
                    </div>
                    <div className="footer-social-links d-flex gap-3">
                      <a href="#">
                        <FaLinkedinIn />
                      </a>
                      <a href="#">
                        <ImFacebook />
                      </a>
                      <a href="#">
                        <AiOutlineInstagram />
                      </a>
                      <a href="#">
                        <AiOutlineTwitter />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="copyright-div">
                  {moment(currentTime).format("YYYY")} © Copyright Tikanga.in
                  Web Marketing
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
