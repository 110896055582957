import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./i18n";
import Events from "./Components/Events/events";
import Home from "./pages/Home/home";
import Signin from "./Components/Signin/signIn";
import Signup from "./Components/SignUp/signUp";
import EventView from "./Components/EventView/eventView";
import "./styles/global.scss";
import Header from "./Components/Header/header";
import Footer from "./Components/Footer/footer";
import Profile from "./pages/Profile/profile";
import { useSelector } from "react-redux";
import PrivateRoute from "./Components/routes/privateRoute";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import UserProfile from "./pages/UserProfile/userProfile";
import Suggested_Account from "./Components/suggested_account/suggested_account";
import ManageEvents from "./Components/ManageEvents/Events";
import ProtectedRoute from "./Components/routes/protectedRoute";
import Horoscope from "./Components/Horoscope/Horoscope";
import PanchangHome from "./Components/Panchang/PanchangHome";
import Kundli from "./Components/Kundli/Kundli";
import Articles from "./Components/Articles/Articles";
import SingleArticle from "./Components/Articles/SingleArticle";
import SingleEvent from "./Components/Events/SingleEvent/SingleEvent";
import Matrimony from "./Components/MatrimonyPackage/Matrimony";
import ReactGA from "react-ga";
import AllTodayDeals from "./Components/BharatWebShop/AllTodayDeals";
import ShopApiContext from "./Components/BharatWebShopContext/ShopTodaysDeals";
import AllNewlyAddedItems from "./Components/BharatWebShop/AllNewlyAddedItems";
import ItemDetails from "./Components/BharatWebShop/ShopItemDetails";
import AllPopularItems from "./Components/BharatWebShop/AllPopularItems";
import CategoryItems from "./Components/BharatWebShop/CategoryItems";
import ProductCategories from "./Components/BharatWebShop/ProductCategories";
import ShopButtonIndex from "./Components/BharatWebShop/ShopButtonIndex";
import FestivalPage from "./Components/Festivals/FestivalPage.js";
import FestivalContext from "./Components/Festivals/FestivalContext.js";
import HolidaysContext from "./Components/Holidays/HolidaysContext.js";
import HolidaysPage from "./Components/Holidays/HolidaysPage.js";
import KundaliBuyIndex from "./Components/KundaliShop/KundaliBuyIndex.js";
import OrderNowForm from "./Components/KundaliShop/OrderNowForm.js";
import Terms from "./TermsAndConditions/Terms.js";
import { GoogleOAuthProvider } from '@react-oauth/google';
import AboutUs from "./pages/AboutUs/aboutus.js";
import ContactUs from "./pages/ContactUs/ContactUs.js";
import FaqPage from "./pages/FAQ/FAQPAGE.js";
// import { Helmet } from "react-helmet";

const MEASUREMENT_ID = process.env.REACT_APP_GA_TRACKING;

function App() {
  const userData = useSelector((state) => state?.user);

  const paramsdata = "en.localhost";

  const [showMenu, setShowMenu] = useState(false);

  const pathname = useLocation().pathname;

  useEffect(() => {
    const currentLng = paramsdata?.split(".");
    // localStorage.setItem("lang", currentLng[0]);
  }, []);

  useEffect(() => {
    if (showMenu) {
      setShowMenu(false);
    }
  }, [pathname]);

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  // useEffect(() => {
  //   window.ga_debug = { trace: true };
  //   return () => {
  //     window.ga_debug = undefined;
  //   };
  // }, []);

  // ReactGA.initialize(MEASUREMENT_ID);

  useEffect(() => {
    // Enable debug mode
    window.ga_debug = { trace: true };

    // Initialize Google Analytics
    ReactGA.initialize(MEASUREMENT_ID);

    // Set up pageview tracking
    ReactGA.pageview(window.location.pathname + window.location.search);

    return () => {
      window.ga_debug = undefined;
    };
  }, []);

  return (
    <div className="app">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {/* <Helmet>
        <title>Bharat Calenders App</title>
        <meta
          name="description"
          content="Discover the cultural richness and vibrant traditions of Bharat with Bharat Calendars - your go-to application for staying connected with festivals, events, and important dates across the country."
        />
      </Helmet> */}
        <Suspense fallback={null}>
          <Header showMenu={showMenu} setShowMenu={setShowMenu} />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PrivateRoute
                  isAuthenticated={userData?.isAuthenticated}
                  newUser={userData?.newUser}
                />
              }
            >
              <Route exact path="/" element={<Home />} />
              <Route exact path="/signin" element={<Signin />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/event" element={<Events />} />
              {/* <Route exact path="/event/:id" element={<EventView />} />     */}
              <Route exact path="/event/:id" element={<SingleEvent />} />
              <Route exact path="/userprofile/:id" element={<UserProfile />} />
              <Route exact path="/suggested" element={<Suggested_Account />} />
              <Route exact path="/horoscope" element={<Horoscope />} />
              <Route exact path="/panchang" element={<PanchangHome />} />
              <Route exact path="/kundli" element={<Kundli />} />
              <Route exact path="/articles" element={<Articles />} />
              <Route exact path="/article/:id" element={<SingleArticle />} />
            </Route>
            <Route exact path="/matrimony" element={<Matrimony />} />
            <Route
              element={
                <ProtectedRoute isAuthenticated={userData?.isAuthenticated} />
              }
            >
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/e/manage/:events" element={<ManageEvents />} />
            </Route>
            <Route
              exact
              path="/shop"
              element={
                <ShopApiContext>
                  <ShopButtonIndex />
                </ShopApiContext>
              }
            />
            <Route
              exact
              path="/all-today-deals"
              element={
                <ShopApiContext>
                  <AllTodayDeals />
                </ShopApiContext>
              }
            />
            {/* <Route
            exact
            path="/all-today-deals/:itemId"
            element={
              <ShopApiContext>
                <ItemDetails />
              </ShopApiContext>
            }
          /> */}
            <Route
              exact
              path="/all-newly-added"
              element={
                <ShopApiContext>
                  <AllNewlyAddedItems />
                </ShopApiContext>
              }
            />
            <Route
              exact
              path="/all-popular-items"
              element={
                <ShopApiContext>
                  <AllPopularItems />
                </ShopApiContext>
              }
            />
            <Route
              path="/category/:categoryId"
              element={
                <ShopApiContext>
                  <CategoryItems />
                </ShopApiContext>
              }
            />

            <Route
              exact
              path="/item/:itemId"
              element={
                <ShopApiContext>
                  <ItemDetails />
                </ShopApiContext>
              }
            />
            <Route
              exact
              path="/festivals"
              element={
                <FestivalContext>
                  <FestivalPage />
                </FestivalContext>
              }
            />
            <Route
              exact
              path="/holidays"
              element={
                <HolidaysContext>
                  <HolidaysPage />
                </HolidaysContext>
              }
            />
            <Route exact path="/aboutus" element={<AboutUs />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/faq" element={<FaqPage />} />
            <Route exact path="/buykundli" element={<KundaliBuyIndex />} />
            <Route exact path="/checkout" element={<OrderNowForm />} />
            <Route exact path="/termsandconditions" element={<Terms />} />
          </Routes>
          <Footer />
        </Suspense>
        <ToastContainer />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
