import "./events.scss";
import { FaRegHandPointRight } from "react-icons/fa";
import Card from "../Card/card";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreatedEventApi,
  getEventsCategoryList,
} from "../../store/actions/eventActions";
import { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import NewEvent from "../Modal/newEvent/newEvent";
import LoginModal from "../Modal/Login/LoginModal";
import EventsHeading from "./EventsHeading";
import EventsCategoryButtons from "./EventsCategoryButtons";
import { Helmet } from "react-helmet-async";

function Events() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userData = useSelector((state) => state?.user);
  const token = localStorage.getItem("token");
  const categoryList = useSelector((state) => state?.event?.categoryList);

  const allEvents = useSelector((state) => state?.event?.eventsAll);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  const handleCloseCreate = () => setShowCreate(false);
  const [showLogin, setShowLogin] = useState(false);
  const [status, setStatus] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const completeProfileToast = () => {
    toast.warning("Please Complete Your Profile First", {
      theme: "colored",
    });
  };
  useEffect(() => {
    dispatch(
      getCreatedEventApi({
        categoryId: categoryId,
        language: localStorage.getItem("REACT_APP_LANGUAGE"),
        eventStatus: "APPROVED",
        sortBy: "createdAt",
        sortOrder: "-1",
      })
    );
  }, [categoryId]);

  useEffect(() => {
    dispatch(
      getEventsCategoryList({
        language: localStorage.getItem("REACT_APP_LANGUAGE"),
      })
    );
  }, []);

  useEffect(() => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          if (position?.coords?.latitude && position?.coords?.longitude) {
            localStorage.setItem(
              "location",
              JSON.stringify({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            );
          }
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setStatus("Unable to retrieve your location");
        }
      );
    }
  }, []);

  return (
    <Container fluid>
      <Helmet>
        <title>Explore Vibrant Events Across Bharat - Bharat Calendars</title>
        <meta
          name="description"
          content="Immerse yourself in the rich cultural tapestry of Bharat by discovering and participating in diverse events. Our Events page is your gateway to a myriad of celebrations, festivals, and gatherings. Stay connected, embrace traditions, and make lasting memories. Join the festivities with Bharat Calendars!"
        />
      </Helmet>
      <div
        style={{
          padding: "5.3%",
          marginTop: "1.46vw",
          backgroundColor: "#FFE8D8",
        }}
      >
        <EventsHeading />
        <EventsCategoryButtons
          categoryList={categoryList}
          categoryName={categoryName}
          setCategoryName={setCategoryName}
          setCategoryId={setCategoryId}
        />
        <Row className="mt-5 mx-4 gap-4 py-5">
          {allEvents?.length !== 0 ? (
            allEvents?.data?.map((value, index) => {
              return (
                <Col
                  key={index}
                  //className="flex-row col-lg-5 col-md-6 align-content-center"
                  //className="col-12 col-sm-6 col-lg-4"
                  xs={12}
                  sm={6}
                  lg={5}
                >
                  <Card event={value} index={index} />
                </Col>
              );
            })
          ) : (
            <Col
              //className="col-lg-12"
              lg={12}
            >
              <div className="no-event-found text-center">
                <img
                  alt=""
                  src="/img/no-event-icon.jpg"
                  align="No-Events"
                  className="not-found-events"
                />
                <h5 className="mt-3" id="text-notfound">
                  Events not found.
                </h5>
              </div>
              <br />
            </Col>
          )}
        </Row>
        {/* <div className="list-bg"></div> */}
        {/* <div className="container list-widget bg-effect"> */}
        {/* <div id="create-event-box" className="row"> */}
        {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-4"> */}
        {/* {token ? (
                <button
                  type="button"
                  className="btn btn-primary create-event-button"
                  onClick={() =>
                    userData?.newUser === false
                      ? setShowCreate(true)
                      : completeProfileToast()
                  }
                >
                  {t("Create Events")}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary create-event-button"
                  onClick={() => setShowLogin(true)}
                >
                  {t("Create Events")}
                </button>
              )} */}
        {/* <h2 className="text-center events-title font-weight-bold">
                {t("Events_For_You")}
              </h2> */}
        {/* </div> */}
        {/* <div className="detail"> */}
        {/* <div className="d-flex justify-content-center mt-4 events"> */}
        {/* <DropdownButton
                  id="dropdown-basic-button"
                  title={!categoryName ? t("Category") : categoryName}
                  style={{ marginLeft: "80px" }}
                >
                  {categoryList &&
                    categoryList?.map((data, index) => {
                      return (
                        <Dropdown.Item
                          href="#"
                          key={index}
                          onClick={() => {
                            setCategoryId(data?._id);
                            setCategoryName(data?.categoryName);
                          }}
                        >
                          <FaRegHandPointRight /> {data?.categoryName}
                        </Dropdown.Item>
                      );
                    })}
                </DropdownButton> */}
        {/* <DropdownButton id="dropdown-basic-button1" title="Date">
                  <Dropdown.Item href="#">All Dates</Dropdown.Item>
                  <Dropdown.Item href="#">
                    <FaRegHandPointRight /> Today
                  </Dropdown.Item>
                  <Dropdown.Item href="#">
                    <FaRegHandPointRight /> Tomorrow
                  </Dropdown.Item>
                  <Dropdown.Item href="#">
                    <FaRegHandPointRight /> This Week
                  </Dropdown.Item>
                  <Dropdown.Item href="#">
                    <FaRegHandPointRight /> This Month
                  </Dropdown.Item>
                  <Dropdown.Item href="#">
                    <FaRegHandPointRight /> Custom Date
                  </Dropdown.Item>
                </DropdownButton> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="row mt-5">
            {allEvents?.length !== 0 ? (
              allEvents?.data?.map((value, index) => {
                return (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                  >
                    <Card event={value} index={index} />
                  </div>
                );
              })
            ) : (
              <div className="col-lg-12">
                <div className="no-event-found text-center">
                  <img
                    alt=""
                    src="/img/no-event-icon.jpg"
                    align="No-Events"
                    className="not-found-events"
                  />
                  <h5 className="mt-3" id="text-notfound">
                    Events not found.
                  </h5>
                </div>
                <br />
              </div>
            )}
          </div> */}
        {/* </div> */}
        {showCreate && userData?.isAuthenticated === true && (
          <NewEvent show={showCreate} close={handleCloseCreate} />
        )}
        <LoginModal
          show={showLogin}
          handleCloseLogin={() => setShowLogin(false)}
          lng={lng}
          lat={lat}
        />
      </div>
    </Container>
  );
}

export default Events;
