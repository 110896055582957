import React, { useEffect, useState } from "react";
import "./SingleEvent.scss";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { eventByIdApi, showId } from "../../../store/actions/eventActions";
import { geteventStatus } from "../../../store/actions/statusActions";
import {
  getInvitedUsers,
  getUserList,
} from "../../../store/actions/userActions";
import Attachment from "../../Attachments/attachment";
import ShareModal from "../../Modal/Share/Sharemodal";
import UserList from "../../Modal/userList/userList";
import Sharefriends from "../../shareFrnds/shareFriends";
import Invited from "../../Modal/invited/invited";
import { useTranslation } from "react-i18next";
import NewEvent from "../../Modal/newEvent/newEvent";
import LoginModal from "../../Modal/Login/LoginModal";
import { ImLocation } from "react-icons/im";
import { FaEdit } from "react-icons/fa";
import { TiLocationArrow } from "react-icons/ti";
import { ReactComponent as PublicIcon } from "../../../img/icons/public.svg";
import { ReactComponent as PrivateIcon } from "../../../img/icons/private.svg";
import { ReactComponent as TickIcon } from "../../../img/icons/tick.svg";
import { ReactComponent as ShareIcon } from "../../../img/icons/share.svg";
import { ReactComponent as CalendarIcon } from "../../../img/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../../../img/icons/clock.svg";
import { ReactComponent as LocationIcon } from "../../../img/icons/location.svg";
import { ReactComponent as CallIcon } from "../../../img/icons/call.svg";
import { ReactComponent as SmsIcon } from "../../../img/icons/sms.svg";

const SingleEvent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const eventId = useParams();
  const { t } = useTranslation();
  const userData = useSelector((state) => state?.user);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const event = useSelector(showId);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showUserList, setShowUserList] = useState(false);
  const handleCloseUserList = () => setShowUserList(false);
  const handleShowUserList = () => setShowUserList(true);
  const [showInvited, setShowInvited] = useState(false);
  const handleCloseInvited = () => setShowInvited(false);
  const handleShowInvited = () => setShowInvited(true);
  const [status, setStatus] = useState("");
  const userList = useSelector((state) => state?.SearchUser?.userlist);
  const invitedUsers = useSelector((state) => state?.SearchUser?.invited);
  const latlong = JSON.parse(localStorage.getItem("location"));
  const [showCreate, setShowCreate] = useState(false);
  const handleCloseCreate = () => setShowCreate(false);
  const handleShowCreate = () => setShowCreate(true);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);

  useEffect(() => {
    const eventData = {
      eventId: eventId?.id,
      userId: userData?.userId,
    };
    dispatch(eventByIdApi(eventData));
  }, [eventId, dispatch, userData?.userId]);

  useEffect(() => {
    if (token && !event?.isPrivate && event?.userStatus === null && status) {
      const data = {
        eventId: eventId?.id,
        usersId: userData?.userId,
        status: status.toLowerCase(),
      };
      dispatch(geteventStatus(data));
    }
  }, [status]);
  function handleUserList() {
    const data = {
      eventId: eventId?.id,
      user: true,
      status: "attending",
      userId: userData?.userId,
    };
    dispatch(getUserList(data));
  }
  function handleInvitedUsers() {
    const data = {
      eventId: eventId?.id,
      user: true,
      status: "invited",
    };
    dispatch(getInvitedUsers(data));
  }

  return (
    <div
      style={{
        padding: "5.3%",
        marginTop: "1.46vw",
        backgroundColor: "#E6FFE0",
      }}
      className="mt-5"
    >
      <h1 className="route-heading mt-4">{event?.title}</h1>
      <div className="d-flex justify-content-between flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row gap-5">
        <div className="d-flex gap-2  align-items-center">
          <div
            className="event-type-button fs-4 montserrat-font d-flex justify-content-center align-items-center"
            style={{
              background: `${
                event?.categoryId?.categoryName === "Social Event"
                  ? "#85D3FF"
                  : event?.categoryId?.categoryName === "Temple Event"
                  ? "#7EFD5E"
                  : event?.categoryId?.categoryName === "Family Event"
                  ? "#FF8FE0"
                  : event?.categoryId?.categoryName === "Prayer Event" &&
                    "#CBFF37"
              }`,
            }}
          >
            {event?.categoryId?.categoryName}
          </div>
          <div>
            {event?.isPrivate ? (
              <PrivateIcon style={{ height: "50px", width: "50px" }} />
            ) : (
              <PublicIcon style={{ height: "50px", width: "50px" }} />
            )}
          </div>
        </div>

        <div className="d-flex gap-3">
          <button
            style={{
              background: "#483F50",
              borderRadius: "10px",
              height: "65px",
              width: "65px",
              border: 0,
            }}
          >
            <TickIcon style={{ height: "40px" }} />
          </button>

          <button
            style={{
              background: "#483F50",
              borderRadius: "10px",
              height: "65px",
              width: "65px",
              border: 0,
            }}
          >
            <ShareIcon style={{ height: "40px" }} />
          </button>
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row  gap-4 mt-5">
        <div>
          <img
            src={event?.imageURL}
            alt=""
            className="event-img img-fluid w-100"
          />
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-column gap-3">
            <h1
              className="montserrat-font"
              style={{ fontSize: "30px", fontWeight: "bold" }}
            >
              {t("Description")}
            </h1>
            <h1 className="montserrat-font mb-4" style={{ fontSize: "30px" }}>
              {event?.description}
            </h1>
          </div>
          <div className="d-flex gap-3 mb-2">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "10px",
                background: `${
                  event?.categoryId?.categoryName === "Social Event"
                    ? "#85D3FF"
                    : event?.categoryId?.categoryName === "Temple Event"
                    ? "#7EFD5E"
                    : event?.categoryId?.categoryName === "Family Event"
                    ? "#FF8FE0"
                    : event?.categoryId?.categoryName === "Prayer Event" &&
                      "#CBFF37"
                }`,
              }}
            >
              <CalendarIcon
                style={{
                  height: "20px",
                  width: "20px",
                }}
              />
            </div>
            <span className="montserrat-font" style={{ fontSize: "28px" }}>
              {moment(event?.startDate).format("dddd, MMM Do")} -{" "}
              {moment(event?.endDate).format("MMM Do yyyy")}
            </span>
          </div>
          <div className="d-flex gap-3 mb-2">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "10px",
                background: `${
                  event?.categoryId?.categoryName === "Social Event"
                    ? "#85D3FF"
                    : event?.categoryId?.categoryName === "Temple Event"
                    ? "#7EFD5E"
                    : event?.categoryId?.categoryName === "Family Event"
                    ? "#FF8FE0"
                    : event?.categoryId?.categoryName === "Prayer Event" &&
                      "#CBFF37"
                }`,
              }}
            >
              <ClockIcon
                style={{
                  height: "20px",
                  width: "20px",
                }}
              />
            </div>
            <span className="montserrat-font" style={{ fontSize: "20px" }}>
              {event?.startTime} - {event?.endTime}
            </span>
          </div>
          <div className="d-flex gap-3  mb-4">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "10px",
                background: `${
                  event?.categoryId?.categoryName === "Social Event"
                    ? "#85D3FF"
                    : event?.categoryId?.categoryName === "Temple Event"
                    ? "#7EFD5E"
                    : event?.categoryId?.categoryName === "Family Event"
                    ? "#FF8FE0"
                    : event?.categoryId?.categoryName === "Prayer Event" &&
                      "#CBFF37"
                }`,
              }}
            >
              <LocationIcon
                style={{
                  height: "20px",
                  width: "30px",
                }}
              />
            </div>
            <span
              className="montserrat-font text-decoration-underline "
              style={{ fontSize: "20px" }}
            >
              {event?.address}
            </span>
          </div>
          {token &&
          !event?.isPrivate &&
          !event?.userStatus &&
          event?.usersId?._id !== userData?.userId &&
          moment(new Date()).format("yyyy-MM-DD") <
            moment(event?.endDate).format("yyyy-MM-DD") ? (
            <div className="d-flex align-items-center event-status-div">
              <span className="event-status-heading">Status</span>
              <span>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-button event-status-value"
                    drop="down"
                  >
                    {!status ? "Select Status" : status}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      border: "unset",
                      borderRadius: "unset",
                      width: "100%",
                    }}
                  >
                    <Dropdown.Item
                      href="#"
                      onClick={() => setStatus("Attending")}
                    >
                      Attending
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => setStatus("Not Attending")}
                    >
                      Not Attending
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => setStatus("May be Attending")}
                    >
                      May be attending
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </div>
          ) : moment(new Date()).format("yyyy-MM-DD") >
            moment(event?.endDate).format("yyyy-MM-DD") ? (
            <div>
              <span className="montserrat-font">Event is Ended</span>
            </div>
          ) : (
            event?.userStatus && (
              <div className="d-flex event-status-div">
                <span className="event-status-heading">Status</span>
                <span className="event-status-value">{event?.userStatus}</span>
              </div>
            )
          )}
          <div className="d-flex justify-content-start gap-4 mb-5">
            <button
              className="border-0 montserrat-font text-white"
              id="eventRegister"
              onClick={() => {
                if (!token) {
                  setShowLogin(true);
                } else {
                  handleUserList();
                  handleShowUserList();
                }
              }}
              style={{
                fontSize: "20px",
                height: "85px",
                width: "170px",
                borderRadius: "10px",
                background: "#483F50",
              }}
            >
              {t("Event_Participants")}
            </button>
            <button
              className="border-0 montserrat-font text-white"
              style={{
                fontSize: "20px",
                height: "85px",
                width: "170px",
                borderRadius: "10px",
                background: "#483F50",
              }}
              onClick={() => {
                if (!token) {
                  setShowLogin(true);
                } else {
                  handleInvitedUsers();
                  handleShowInvited();
                }
              }}
            >
              {t("Invited_Users")}
            </button>
          </div>
          {!token &&
            moment(new Date()).format("yyyy-MM-DD") <
              moment(event?.endDate).format("yyyy-MM-DD") && (
              <button
                className="border-0 montserrat-font text-white w-100"
                style={{
                  cursor: "pointer",
                  height: "90px",
                  borderRadius: "10px",
                  background: "#EB7625",
                }}
                onClick={() => !token && setShowLogin(true)}
              >
                Login to Attend
              </button>
            )}
          {event?.usersId?._id === userData?.userId &&
            moment(new Date()).format("yyyy-MM-DD") <
              moment(event?.endDate).format("yyyy-MM-DD") && (
              <div>
                <button
                  className="border-0 montserrat-font text-white w-100"
                  style={{
                    cursor: "pointer",
                    height: "90px",
                    borderRadius: "10px",
                    background: "#EB7625",
                  }}
                  onClick={() => {
                    handleShowCreate();
                    setSelectedEvent(event);
                  }}
                >
                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <FaEdit className="fs-4"></FaEdit>
                    <span className="fs-4">Edit Event</span>
                  </div>
                </button>
              </div>
            )}
        </div>
      </div>
      <div className="d-flex flex-column gap-4" style={{ marginTop: "100px" }}>
        <h1
          className="route-heading mb-3"
          style={{ fontSize: "45px", fontWeight: "bold" }}
        >
          {t("About the Event")}
        </h1>
        <h1 className="montserrat-font" style={{ fontSize: "30px" }}>
          {event?.description}
        </h1>
      </div>
      <div className="d-flex flex-column gap-4" style={{ marginTop: "100px" }}>
        <h1
          className="route-heading mb-3"
          style={{ fontSize: "45px", fontWeight: "bold" }}
        >
          {t("Media Attachments")}
        </h1>
        <h1 className="montserrat-font" style={{ fontSize: "30px" }}>
          {event?.attachments && event?.attachments?.length > 0 && (
            <Attachment attachments={event?.attachments} />
          )}
        </h1>
      </div>
      <div className="d-flex flex-column gap-4" style={{ marginTop: "100px" }}>
        <h1
          className="route-heading mb-3"
          style={{ fontSize: "45px", fontWeight: "bold" }}
        >
          {t("Location")}
        </h1>
        <div className="pt-0">
          <div>
            <div className=" descripton-content">
              <div className="d-flex">
                <div className="date">
                  {/* <div className="location_icon">
                    <ImLocation
                      style={{
                        height: "20px",
                        width: "20px",
                        color: "#00ab44",
                      }}
                    />
                  </div> */}
                </div>
                <div className="col-lg-6 col-sm-12 col-md-6 date">
                  <div className="montserrat-font fs-5">{event?.address}</div>
                  <div className="montserrat-font fs-5">{event?.address2}</div>
                  <div className="col-lg-6 col-md-6">
                    <button
                      type="submit"
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/dir/${latlong?.lat},${latlong?.lng}/${event?.location?.coordinates[1]},${event?.location?.coordinates[0]}`,
                          "_blank"
                        )
                      }
                      className="d-flex align-items-center border-0 montserrat-font text-white text-uppercase mt-3 px-3"
                      style={{
                        cursor: "pointer",
                        height: "75px",
                        borderRadius: "10px",
                        background: "#EB7625",
                      }}
                    >
                      <TiLocationArrow
                        style={{
                          height: "40px",
                          width: "40px",
                          color: "white",
                        }}
                      />
                      <span>{t("Direction")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {event?.usersId !== null && event?.usersId !== undefined && (
        <div
          className="d-flex flex-column gap-4"
          style={{ marginTop: "100px" }}
        >
          <h1
            className="route-heading mb-3"
            style={{ fontSize: "45px", fontWeight: "bold" }}
          >
            {t("Event Organizer")}
          </h1>
          <div className="d-flex flex-column flex-lg-row  gap-5">
            {event?.usersId?.imageURL ? (
              <img
                style={{ height: "170px", width: "170px", borderRadius: "50%" }}
                src={event?.usersId?.imageURL}
                alt=""
              />
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "170px",
                  width: "170px",
                  borderRadius: "50%",
                  background: "#EB7625",
                }}
              >
                <span
                  className="montserrat-font text-uppercase text-white"
                  style={{ fontSize: "70px" }}
                >
                  {event?.usersId?.firstName?.charAt(0)}
                </span>
              </div>
            )}
            <div className="d-flex flex-column gap-3">
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "35px",
                  fontWeight: 700,
                  marginBottom: 0,
                }}
              >
                {event?.usersId?.userName}
              </h1>
              {event?.usersId?.userMob && (
                <div className="d-flex align-items-center gap-3">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      background: "#483F50",
                    }}
                  >
                    <CallIcon />
                  </div>
                  <span>
                    <span className="montserrat-font">
                      {event?.usersId?.userMob}
                    </span>
                  </span>
                </div>
              )}

              <div className="d-flex align-items-center gap-3">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                    background: "#483F50",
                  }}
                >
                  <SmsIcon />
                </div>
                <span className="montserrat-font">
                  {event?.usersId?.userEmail}
                </span>
              </div>

              <a
                href={`mailto:${event?.usersId?.userEmail}`}
                className="text-white text-decoration-none"
              >
                <button
                  className="mt-3 border-0 montserrat-font text-white text-uppercase px-5"
                  style={{
                    cursor: "pointer",
                    height: "75px",
                    borderRadius: "10px",
                    background: "#EB7625",
                  }}
                >
                  {t("Contact Me")}
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
      <ShareModal show={show} close={handleClose} />
      <UserList
        show={showUserList}
        close={handleCloseUserList}
        userlist={userList}
      />
      <Invited
        show={showInvited}
        close={handleCloseInvited}
        invitedUsers={invitedUsers}
        event={event}
      />
      {showCreate && (
        <NewEvent
          show={showCreate}
          close={handleCloseCreate}
          event={selectedEvent ? selectedEvent : ""}
          setSelectedEvent={setSelectedEvent}
        />
      )}
      <LoginModal
        show={showLogin}
        handleCloseLogin={() => handleCloseLogin()}
      />
    </div>
  );
};

export default SingleEvent;
