import React from "react";
import { useState } from "react";
import ArticleListing from "./ArticleListing";
import ArticlesCategories from "./ArticlesCategories";
import GoogleAdSense from "../../GoogleAdSense/GoogleAdSense";
import { Helmet } from "react-helmet-async";

const Articles = () => {
  const [articleId, setArticleId] = useState("");
  const handleArticleId = (id) => {
    // console.log(id);
    setArticleId(id);
  };
  return (
    <>
      <Helmet>
        <title>
          Explore Enlightening Articles on Astrology and Spirituality
        </title>
        <meta
          name="description"
          content="Dive into a world of wisdom and spirituality with Bharat Calendars. Our Articles section offers a rich collection of insightful and enlightening articles on astrology, spirituality, and cosmic guidance. Gain knowledge, find inspiration, and embark on a journey of self-discovery through our carefully curated articles."
        />
      </Helmet>
      <div
        style={{ paddingTop: "4.2%", margin: "20px" }}
        className="w-75 mx-auto"
      >
        <ArticlesCategories handleArticleId={handleArticleId} />
        <div style={{ margin: "8px" }}>
          <GoogleAdSense />
        </div>
        <ArticleListing articleId={articleId} />
      </div>
    </>
  );
};

export default Articles;
